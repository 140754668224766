<template>
  <div class="ad2-container">
    <ModuleTextHeader :title="title" :describe="describe" class="ad2-header" />
    <div v-swiper:mySwiper="swiperOption" class="swiper-container">
      <div class="swiper-wrapper">
        <div v-for="(item, index) in dataList" :key="index" class="swiper-slide">
          <DiffLinkLabel :url="item.linkUrl" :target="linkTarget">
            <div class="ad2-content" @click="cardTrackReport({ cardName: item.title })">
              <img class="swiper-lazy" :data-src="item.imageUrl" alt="">
              <div class="text-overflow-ellipsis-2 swiper-title">
                {{ item.title }}
              </div>
              <div class="swiper-desc">
                {{ item.describe }}
              </div>
            </div>
          </DiffLinkLabel>
        </div>
      </div>
    </div>
    <div v-if="buttonName" class="button btn-wraper" @click="buttonTrackReport({ buttonName })">
      <DiffLinkLabel :url="buttonUrl" :target="linkTarget">
        <div class="ad2-btn text-overflow-ellipsis">
          {{ buttonName }}
        </div>
      </DiffLinkLabel>
    </div>
  </div>
</template>
<script>
import mixin from './mixin'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import trackReportMixin from '../Common/CommonMixin/trackReport'
import linkTargetMixin from '../Common/CommonMixin/linkTarget'
import ModuleTextHeader from '../Common/ModuleTextHeader/touch.vue'
import DiffLinkLabel from '../Common/DiffLinkLabel/touch.vue'

export default {
  name: 'Ad2',
  /* eslint-disable-next-line */
  components: { ModuleTextHeader, DiffLinkLabel, Swiper, SwiperSlide, },
  directives: {
    swiper: directive,
  },
  mixins: [mixin, trackReportMixin, linkTargetMixin],
  data() {
    return {
      swiperOption: {
        slidesPerView: 'auto',
        loop: true,
        lazy:{
          loadPrevNext:true, // 预加载前一个后一个
        },
        observer:true, // 当Swiper的监听器检测到swiper的一些属性变化时，会更新Swiper的状态
        observeParents:true, // 当Swiper的父元素变化时，也会更新Swiper的状态
      },
    }
  },
}

</script>
<style lang="scss" scoped>
.ad2-container {
  overflow: hidden;

  .ad2-header {
    margin: 20px;
  }

  .swiper-container {
    margin-left: 16px;

    .swiper-slide {
      width: 285px;
      margin-right: 10px;
    }

    .ad2-content {
      width: 285px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-size: 14px;
      font-weight: bold;
      line-height: 20px;

      img {
        width: 285px;
        height: 146px;
      }

      .swiper-title {
        width: 285px;
        margin-top: 10px;
        font-size: 14px;
        font-weight: 600;
        color: var(--txt-color-lv1);
        line-height: 16px;
      }

      .swiper-desc {
        width: 285px;
        text-align: left;
        margin-top: 10px;
        font-size: 12px;
        font-weight: 400;
        color: var(--txt-color-lv1);
        word-break: break-word;
        line-height: 18px;
      }
    }
  }

  .btn-wraper {
    display: block;
    width: 285px;
    margin: 20px auto 0;
    padding: 10px 20px;
    font-size: 16px;
    line-height: 30px;
    font-weight: bold;
    background: var(--txt-color-link);
    border-radius: 32px;

    .ad2-btn {
      color: #fff;
    }
  }
}
</style>
