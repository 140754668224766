<template>
  <div
    id="chat-message-list"
    ref="scrollList"
    class="chat-message-list"
  >
    <template v-for="(message, idx) in messages">
      <Message
        :key="idx"
        :message="message"
        @retry="handleRetry"
      />
    </template>
  </div>
</template>

<script>
import mixin from './mixin.js';
import Message from './Message/touch.vue'

export default {
  components: {
    Message,
  },
  mixins: [mixin],
  props: {
    bottomHeight: {
      default: 56,
      type: Number,
    },
  },
  data() {
    return {
      headerHeight: 46, // 顶部默认高度
      initialY: 0,
    }
  },
  watch: {
    bottomHeight(newValue, oldValue) {
      if (newValue !== oldValue) this.fixScrollheight(newValue)
    },
  },
  mounted() {
    this.stopEndssMove()
  },
  methods: {
    stopEndssMove() {
      const el = document.getElementById('chat-message-list')
      el.ontouchstart = function (e) {
        if (e.targetTouches.length === 1) {
          // 单点滑动
          this.initialY = e.targetTouches[0].clientY;
        }
      }
      /* eslint-disable-next-line */
      el.ontouchmove = function (e) {
        if (e.targetTouches.length === 1) {
          // 单点滑动
          const clientY = e.targetTouches[0].clientY - this.initialY;
          // 滑到底部
          if (el.scrollTop + el.clientHeight >= el.scrollHeight && clientY < 0) {
            return e.preventDefault();
          }
          // 滑到顶部
          if (el.scrollTop <= 0 && clientY > 0) {
            return e.preventDefault();
          }
        }
      }
    },
    fixScrollheight(value) {
      this.$refs.scrollList.style.height = `calc(100% - ${value + this.headerHeight}px)`
    },
  },
}
</script>

<style scoped>
.chat-message-list {
  height: calc(100% - 102px);
  overflow-y: auto;
  padding: 30px 16px;
  overscroll-behavior: contain;
}
</style>
