<template>
  <div class="template-box">
    <div class="theme" v-if="theme">{{ theme }}</div>
    <div class="themeIntro" v-if="themeIntro">{{ themeIntro }}</div>
    <div class="swiper-pagination"></div>
    <div
      class="swiper-box"
      @mouseenter="handleMouseenter"
      @mouseleave="handleMouseleave"
    >
      <div
        v-swiper:mySwiper="swiperOption"
        class="swiper-container"
        ref="swiper"
      >
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(item, index) in layout"
            :key="index"
          >
            <template v-if="item.proportion == '5:5'">
              <div class="flex-box type55">
                <div class="l-box" v-lazy-html="changeSrc(item.leftBox)" v-html="changeSrc(item.leftBox)"></div>
                <div class="r-box" v-lazy-html="changeSrc(item.rightBox)" v-html="changeSrc(item.rightBox)"></div>
              </div>
            </template>
            <template v-if="item.proportion == '4:6'">
              <div class="flex-box type46">
                <div class="l-box" v-lazy-html="changeSrc(item.leftBox)" v-html="changeSrc(item.leftBox)"></div>
                <div class="r-box" v-lazy-html="changeSrc(item.rightBox)" v-html="changeSrc(item.rightBox)"></div>
              </div>
            </template>
            <template v-if="item.proportion == '6:4'">
              <div class="flex-box type64">
                <div class="l-box" v-lazy-html="changeSrc(item.leftBox)" v-html="changeSrc(item.leftBox)"></div>
                <div class="r-box" v-lazy-html="changeSrc(item.rightBox)" v-html="changeSrc(item.rightBox)"></div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from "./mixin";
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
export default {
  mixins: [mixin],
  components: { Swiper, SwiperSlide },
  directives: {
    swiper: directive,
    'lazy-html': {
      inserted(el, binding) {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              const lazyLoadEls = el.querySelectorAll('[data-src]');
              Array.from(lazyLoadEls).forEach((img) => {
                img.src = img.getAttribute('data-src');
              });
              observer.unobserve(entry.target);
            }
          });
        });
        observer.observe(el);
      }
    }
  },
  data(props) {
    return {
      swiperOption: {
        slidesPerView: "auto",
        // loop: true,
        preloadImages: false,
        loopAdditionalSlides: 2,
        loopedSlides: 5,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        lazy:{
          loadPrevNext: false,
        },
        speed: 500,
        autoplayDisableOnInteraction: true,
        observer: true,
        observeParents: true,
        observeSlideChildren: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          renderBullet: function (index, className) {
            return (
              '<span class="' +
              className +
              '">' +
              props.layout[index].title +
              "</span>"
            );
          },
        },
      },
    };
  },
  methods: {
    handleMouseenter() {
      this.mySwiper.autoplay.stop();
      console.log("触发");
    },
    handleMouseleave() {
      this.mySwiper.autoplay.start();
      console.log("触发");
    },
    changeSrc(txt){
      const regex = /(https?:\/\/[^\s]+)/g;
      let linkBox = txt.match(regex);
      let txtBox = txt.split("<img")
      if( linkBox && linkBox.length > 0){
        linkBox.forEach((i) => {
          txtBox.forEach((e, index) => {
            if(e.indexOf('data-src') != -1) return
            txtBox[index] = e.replace('src=', `<img width="100%" height="auto" data-src="${i}" v-lazy-load data-src=`)
          })
        })
      }
      let newTxt = ''
      txtBox.forEach((e) => {
        newTxt = newTxt + e
      })
      //  let newTxt = txt.replace('src=', 'width="100%" height="auto "data-src=')
      return newTxt
    }
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.template-box {
  padding:0 16px 0;
  .theme {
    font-size: 24px;
    font-family: Montserrat-Bold, Montserrat;
    font-weight: bold;
    color: #222222;
    line-height: 24px;
    margin-bottom: 20px;
    text-align: center;
  }
  .themeIntro {
    font-size: 14px;
    font-family: Montserrat-Regular, Montserrat;
    font-weight: 400;
    color: #222222;
    line-height: 14px;
    margin-bottom: 20px;
    text-align: center;
  }
  .l-box,
  .r-box {
    background: #ffffff;
    width: 100%;
    ::v-deep img {
      max-width: 100%;
    }
  }
  .swiper-box {
    // border: 1px solid rgba(0,0,0,0.1);
    // border-radius: 8px;
    overflow: hidden;
    .flex-box {
      ::v-deep ul {
        padding: revert;
      }
      ::v-deep ol {
        padding: revert;
      }
    }
  }
  ::v-deep .swiper-pagination {
    width: 100%;
    position: relative;
    margin-bottom: 16px;
    white-space: nowrap;
    overflow: auto;
    text-align: left;
    /* 隐藏滚动条 - Webkit 浏览器（如 Chrome 和 Safari） */
    ::-webkit-scrollbar {
      display: none;
    }
    /* 隐藏滚动条 - Firefox */
    scrollbar-width: none;
    /* 隐藏滚动条 - IE and Edge */
    -ms-overflow-style: none;
    .swiper-pagination-bullet {
      font-size: 16px;
      font-family: Montserrat-SemiBold, Montserrat;
      font-weight: 600;
      color: #222222;
      background: #f7f7f7;
      line-height: 40px;
      display: inline-block;
      vertical-align: top;
      margin: 0 16px 0 0;
      padding: 0 16px;
      background: #F7F7F7;
      width: auto;
      height: 40px;
      opacity: 1;
      border-radius: 20px;
      &:last-child {
        margin: 0;
      }
    }
    .swiper-pagination-bullet-active {
      background: var(--txt-color-link);
      border-radius: 20px;
      font-size: 16px;
      font-family: Montserrat-Bold, Montserrat;
      font-weight: bold;
      color: #ffffff;
    }
  }
}
</style>