<template>
  <div v-sa-track="[{type: 'visually', event: 'component_card_show', data: cardshowTrackReport({ cardName: componentTitle })}]" class="button-group-fixed-container" :style="{opacity: showFixed? '1': '0'}">
    <div v-if="style==='1' && (renderData.showIcon || renderData.showDescribe)" class="text-description text-1">
      <div v-if="renderData.showIcon" class="icon-box">
        <img v-lazy-load :data-src="remind.iconUrl" alt="">
      </div>
      <div v-if="renderData.showDescribe" class="text">
        {{ describe }}
      </div>
    </div>
    <div v-else-if="style==='2' && (renderData.showIcon || renderData.showDescribe)" class="text-description text-2">
      <div v-if="renderData.showIcon" class="icon-box">
        <img v-lazy-load :data-src="remind.iconUrl" alt="">
      </div>
      <div v-if="renderData.showDescribe" class="text">
        {{ describe }}
      </div>
    </div>
    <div v-if="renderData.showRenew" class="renewLink">
      <a :href="renew.linkUrl" :target="renew.target" @click="cardTrackReport(renew.linkDescribe)">{{ renew.linkDescribe }}</a>
    </div>
    <div class="btn-group">
      <div v-if="renderData.showBtn1" class="touch-btn plain">
        <a v-if="btn1.link" :target="btn1.target" :href="btn1.link" class="ellipsis" @click="cardTrackReport(btn1.text)">{{ btn1.text }}</a>
        <span v-else class="ellipsis">{{ btn1.text }}</span>
      </div>
      <div v-if="renderData.showBtn2" class="touch-btn primary">
        <span v-if="!btn2.link" class="ellipsis">{{ btn2.text }}</span>
        <span v-if="!isLogin && type === 2" class="ellipsis" @click="jumpTo(btn2.link)">{{ btn2.text }}</span>
        <a v-else ref="fixed-btn2" class="ellipsis" :href="btn2.link" :target="btn2.target" @click="cardTrackReport(btn2.text)">{{ btn2.text }}</a>
      </div>
    </div>
  </div>
</template>
<script>
import trackReportMixin from '../Common/CommonMixin/trackReport'
import mixin from './mixin'

export default {
  mixins: [mixin, trackReportMixin],
  data() {
    return {
      current: 'touch',
      style: '1',
      timer: null,
      scrollTop: -1,
      showFixed: true,
      showFixedTimeout: null,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.scrolling)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.scrolling)
  },
  methods: {
    scrolling() {
      this.showFixed = false
      clearTimeout(this.showFixedTimeout)
      this.showFixedTimeout = setTimeout(() => {
        this.showFixed = true
        clearTimeout(this.showFixedTimeout)
      }, 200)
    },
    jumpTo() {
      const currentFullPath = this.$route.fullPath
      const successUrl = encodeURI(currentFullPath)
      this.$router.push({ path: '/login', query: { successUrl } })
    },
  },
}
</script>
<style lang="scss" scoped>
.button-group-fixed-container {
  padding: 0 16px;
  background: #fff;
  box-shadow: 0 5px 8px 0 rgba(180, 180, 180, 0.2);
  font-size: 14px;
  font-weight: 400;

  .text-description {
    position: relative;
    // top: 12px;
    padding-top: 12px;

    .icon-box {
      width: 16px;
      height: 16px;
      margin-top: 12px;
      margin-right: 6px;
      overflow: hidden;

      img {
        width: 100%;
      }
    }

    .text {
      color: var(--txt-color-lv2);
      line-height: 22px;
      overflow: hidden;
    }

    &.text-1 {
      display: flex;
      align-items: flex-start;
      justify-content: center;

      .icon-box {
        flex: 0 0 16px;
        margin-top: 3px;
      }
    }

    &.text-2 {
      .icon-box {
        position: absolute;
        left: 0;
        top: 3px;
      }

      .text {
        padding-left: 20px;
      }
    }
  }

  .renewLink {
    width: 100%;
    padding-top: 8px;
    overflow: hidden;
    text-align: center;

    a {
      height: 22px;
      color: var(--txt-color-link);
      line-height: 22px;
      border-bottom: 1px solid var(--txt-color-link);
      // text-decoration: underline;
    }
  }

  .btn-group {
    width: 100%;
    height: 54px;
    display: flex;
    justify-content: center;
    align-items: center;

    .ellipsis {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .touch-btn {
      width: 120px;
      height: 30px;
      padding: 0 8px;
      border-radius: 70px;
      background: var(--txt-color-link);
      font-size: 12px;
      color: #fff;
      line-height: 30px;
      text-align: center;

      &.plain {
        background: #fff;
        border: 1px solid var(--txt-color-link);
        color: var(--txt-color-link);
      }

      & + .touch-btn {
        margin-left: 16px;
      }

      a {
        color: inherit;
      }
    }
  }
}
</style>
