<template>
  <div class="wrapper ad4-container">
    <ModuleTextHeader :title="title" :describe="describe" />
    <div class="content">
      <van-swipe ref="ad4VanSwipe" class="my-swipe" :autoplay="0" :show-indicators="false" lazy-render>
        <van-swipe-item v-for="(item, index) in dataList" :key="index" @click="handleShowDetail(item)">
          <div class="container" @click="cardTrackReport({ cardName: item.title })">
            <img v-lazy="item.imageUrl" alt="">
            <div class="txt-common">
              <span class="text-overflow-ellipsis-2">
                {{ item.title }}
              </span>
            </div>
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="bottom">
      <div class="btn-swipe" @click="handlePrevSwipe">
        <div class="iconfont icon-prev2" />
      </div>
      <div class="btn-swipe" @click="handleNextSwipe">
        <div class="iconfont icon-next2" />
      </div>
    </div>
    <TextImgOverlay ref="textImgOverlay" />
  </div>
</template>

<script>
/**
 * 广告样式（四）
 */
import mixin from './mixin'
import trackReportMixin from '../Common/CommonMixin/trackReport'
import ModuleTextHeader from '../Common/ModuleTextHeader/touch.vue'
import TextImgOverlay from './TextImgOverlay/touch.vue'

export default {
  name: 'Ad4',
  components: {
    ModuleTextHeader,
    TextImgOverlay,
  },
  mixins: [mixin, trackReportMixin],
  data() {
    return {
      activeIndex: 0,
    }
  },
  methods: {
    handleShowDetail(data) {
      this.$refs.textImgOverlay.showOverlay(data)
    },
    handlePrevSwipe() {
      this.$refs.ad4VanSwipe.prev()
    },
    handleNextSwipe() {
      this.$refs.ad4VanSwipe.next()
    },
  },
}
</script>
<style lang="scss" scoped>
.ad4-container {
  padding: 30px;
  background: #f9f9f9;
}

.content {
  background-color: #fff;
  padding: 16px;
}

.container {
  position: relative;

  img {
    width: 283px;
    height: 160px;
  }

  .txt-common {
    width: 283px;
    padding: 10px;
    position: absolute;
    left: 0;
    bottom: 4px;
    z-index: 2;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    line-height: 18px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.86) 100%);
  }
}

.bottom {
  display: flex;
  justify-content: center;
  margin-top: 16px;
  position: relative;

  .btn-swipe {
    width: 100px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    left: 0;
    top: 0;
    right: 0;
    padding: 9px 0;
    margin: 0 15px;
    background: #fff;
    border-radius: 20px;
    border: 1px solid #f1f1f1;
    background-image: none;
  }
}
</style>
