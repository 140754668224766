<template>
  <div class="courses-column-wrapper">
    <ModuleTextHeader :title="title" :describe="description" />
    <div class="courses-card-wrappoer">
      <div
        v-for="(item, index) in dataList"
        :key="index"
        v-sa-track="[{type: 'visually', event: 'component_card_show', data: cardshowTrackReport({ cardName: item.showName })}]"
        class="courses-card-item-wrapper"
        @click="cardTrackReport({ cardName: item.showName })"
      >
        <CoursesCard :course-detail="item" />
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 课程栏目(二)
 */
import mixin from './mixin'
import trackReportMixin from '../Common/CommonMixin/trackReport'
import ModuleTextHeader from '../Common/ModuleTextHeader/web'
import CoursesCard from './CoursesCard/touch'

export default {
  name: 'CoursesColumn2',
  components: {
    ModuleTextHeader,
    CoursesCard,
  },
  mixins: [mixin, trackReportMixin],
}
</script>

<style lang="scss" scoped>
.courses-column-wrapper {
  padding: 0 16px;
}

.courses-card-wrappoer {
  .courses-card-item-wrapper {
    margin-bottom: 16px;
    border-radius: 10px;
    border: 1px solid #dee2e7;
  }
}
</style>
