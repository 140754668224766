<template>
  <div :class="['common-good-card', {'has-border': hasBorder}]">
    <div class="card-img">
      <img v-if="cardData.banners.length > 0" v-lazy-load v-lazy-load :data-src="cardData.banners[0]">
      <div v-else class="default-banner" />
    </div>
    <div class="card-title">
      <div class="title text-overflow-ellipsis-2">
        {{ cardData.title }}
      </div>
    </div>
    <div class="bottom-summary ellipsis">
      <div class="line" />
      <!-- 价格 -->
      <div class="bottom-content">
        <div v-if="cardData.showOrgPrice > cardData.showPrice" class="old">
          {{ currency }}
          {{
            cardData.showOrgPrice | priceUnitConvert(minorUnit, isKeepTwoDecimal)
          }}
        </div>
        <div class="current">
          <template v-if="cardData.showPrice == 0">
            {{ recordedConfig.free }}
          </template>
          <template v-else>
            {{ currency }}
            {{ cardData.showPrice
              || cardData.sellPrice
              | priceUnitConvert(minorUnit, isKeepTwoDecimal)
            }}
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import mixin from './mixin'

export default {
  mixins: [mixin],
}
</script>
<style lang="scss">
.common-good-card {
  background-color: #fff;
  border-radius: 10px;

  &.has-border {
    border: 1px solid #dee2e7;
  }

  .card-img {
    width: 100%;
    height: 179px;
    padding: 12px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
  }

  .default-banner {
    width: 100%;
    height: 100%;
    background: url("~assets/images/courses/common-default-banner.png");
    background-size: 100%;
    border-radius: 10px;
  }

  .card-title {
    display: flex;
    height: 38px;
    align-items: center;
    font-size: 16px;
    line-height: 19px;
    color: var(--txt-color-lv1);
    margin-bottom: 12px;
    padding: 0 12px;

    .title {
      max-height: 100%;
      vertical-align: middle;
    }
  }

  .bottom-summary {
    height: 54px;
    background: #fff;
    border-radius: 10px;
    font-size: 14px;
    line-height: 54px;
    text-align: right;
    padding: 0 20px;

    .line {
      width: 100%;
      border-top: 1px solid #f2f2f2;
      // overflow: hidden;
    }

    &.is-expired {
      background: linear-gradient(90deg, #fff 0%, #f4f6fa 100%);
      color: var(--txt-color-lv2);
    }

    .bottom-content {
      height: 100%;
      color: var(--txt-color-link);
      line-height: 1;
      font-weight: 600;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 12px;

      &.is-expired {
        background: linear-gradient(90deg, #fff 0%, #f4f6fa 100%);
        color: var(--txt-color-lv2);
      }

      .old {
        height: 16px;
        margin-right: 12px;
        font-size: 16px;
        color: var(--txt-color-lv2);
        text-decoration: line-through;
      }

      .current {
        height: 20px;
        font-size: 20px;
        color: var(--txt-color-link);
      }
    }
  }
}
</style>
