<template>
  <div class="resouce-item-container" :class="resourceRealType" @click="handleClick">
    <div class="left">
      <div class="type-icon" />
    </div>
    <div class="right">
      <div class="name text-overflow-ellipsis">
        {{ resourceName }}
      </div>
      <div class="icon-btn" :class="resourceRealType === 'mp4' ? 'icon_play' : 'icon_download2'" />
    </div>
  </div>
</template>

<script>
import mixin from './mixin'

export default {
  mixins: [mixin],
}
</script>

<style lang="scss" scoped>
.resouce-item-container {
  display: flex;
  align-items: center;
  background: #fff5f4;
  border-radius: 8px;

  .right {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: right;
    padding: 0 10px;

    .name {
      width: 204px;
      margin-right: 10px;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      text-align: right;
    }

    .icon-btn {
      flex-shrink: 0;
      width: 24px;
      height: 24px;
      background: url('./assets/imgs/icon_btn_sprites.png') no-repeat;
      background-size: 100%;
    }

    .icon_play {
      background-position: 0 -48px;
    }

    .icon_download2 {
      background-position: 0 0;
    }
  }

  .left {
    flex-shrink: 0;
    padding: 12px;
    border-right: 1px solid #fff;

    .type-icon {
      width: 30px;
      height: 35px;
      overflow: hidden;
    }
  }

  &.pdf {
    background: #fff5f4;

    .left {
      .type-icon {
        background: url("./assets/imgs/icon_pdf.png") 0/100% no-repeat;
      }
    }
  }

  &.word {
    background: #f3f7ff;

    .left {
      .type-icon {
        background: url("./assets/imgs/icon_word.png") 0/100% no-repeat;
      }
    }
  }

  &.excel {
    background: #f0fcf8;

    .left {
      .type-icon {
        background: url("./assets/imgs/icon_excel.png") 0/100% no-repeat;
      }
    }
  }

  &.mp4 {
    background: #fff9ec;

    .left {
      .type-icon {
        background: url("./assets/imgs/icon_mp4.png") 0/100% no-repeat;
      }
    }
  }

  &:hover {
    .right {
      .icon_play {
        background-position: 0 -72px;
      }

      .icon_download2 {
        background-position: 0 -24px;
      }
    }
  }
}
</style>
