<template>
  <div class="wrapper container">
    <div v-for="(item, index) in moduleList" :key="index" class="container-item">
      <ModuleMoreHeader
        :column-title="item.columnTitle" :more-name="item.moreName" :more-url="item.moreUrl"
        :target="getLinkTarget(item.openType)"
        @more-click="buttonTrackReport({ buttonName: item.moreName })"
      />
      <template v-if="item.styleType === 'bigPicture'">
        <DiffLinkLabel :url="item.linkUrl" :target="getLinkTarget(item.openType)">
          <div class="bigPicture" @click="cardTrackReport({ cardName: item.title })">
            <img v-lazy-load :data-src="item.imageUrl" alt="">
            <div class="big-title text-overflow-ellipsis-2">
              {{ item.title }}
            </div>
            <div class="describe">
              {{ item.describe }}
            </div>
          </div>
        </DiffLinkLabel>
      </template>
      <template v-if="item.styleType === 'smallPicture'">
        <div v-for="(item1, index1) in item.dataList" :key="index1" class="smallPicture">
          <DiffLinkLabel :url="item1.linkUrl" :target="getLinkTarget(item1.openType)">
            <div class="small-content" @click="cardTrackReport({ cardName: item1.title })">
              <img v-lazy-load :data-src="item1.imageUrl" alt="">
              <div class="samll-wraper">
                <div class="small-title text-overflow-ellipsis-2">
                  {{ item1.title }}
                </div>
                <div class="small-desc text-overflow-ellipsis-3">
                  {{ item1.describe }}
                </div>
              </div>
            </div>
          </DiffLinkLabel>
        </div>
      </template>
      <template v-if="item.styleType === 'materialDownload'">
        <div v-for="(item2, index2) in item.dataList" :key="index2" class="materialDownload">
          <div :class="`type-icon ${fileTypeMap[item2.formatType]}`" />
          <div class="material-title text-overflow-ellipsis-2">
            {{ item2.resourceTitle }}
          </div>
          <div class="iconfont icon-download2" @click="getResource(item2.resourceId); cardTrackReport({ cardName: item2.resourceTitle })" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
/**
 * 资讯组件
 */
import mixin from './mixin'
import linkTargetMixin from '../Common/CommonMixin/linkTarget'
import trackReportMixin from '../Common/CommonMixin/trackReport'
import ModuleMoreHeader from '../Common/ModuleMoreHeader/touch.vue'
import DiffLinkLabel from '../Common/DiffLinkLabel/touch.vue'
import { oneApi } from 'config/api'
import { resourceUrl } from 'common/utils.js';

export default {
  name: 'NewsGroup',
  components: {
    ModuleMoreHeader,
    DiffLinkLabel,
  },
  mixins: [mixin, linkTargetMixin, trackReportMixin],
  data() {
    return {
      resourceUrl: resourceUrl(),
    }
  },
  methods: {
    async getResource(id, type) {
      console.log('PC邮箱3')
      if (!this.isLogin) {
        const currentFullPath = this.$route.fullPath
        const backUrl = encodeURI(currentFullPath)
        this.$router.replace(`/login?&cancelUrl=${backUrl}&successUrl=${backUrl}&triggerSource=down`);
        return
      }
      const info = await this.$axios.post(oneApi.queryUserBasicInfo, { fillInAttention: true }, { rewritePostBody: true })
      if (!info.data.email) {
        this.$store.dispatch('bindDialog/setDialogData', {
          visible: true,
          type: 'email',
        })
        return
      }
      if (!info.data.phone) {
        this.$store.dispatch('bindDialog/setDialogData', {
          visible: true,
          type: 'phone',
        })
        return
      }
      const res = await this.$axios.post(oneApi.getUrl, id, { rewritePostBody: true });
      if (Number(res.code) !== 0) {
        this.$ElMessage.error(res.msg);
        return
      }
      const resData = res.data || {}
      this.$router.push({ name: 'resources-view-id', params: { id }, query: { resource: this.resourceUrl + resData.url, type, name: resData.fileName } })
    },
  },
}
</script>
<style lang="scss" scoped>
.container {
  .container-item {
    margin-bottom: 40px;
  }

  .bigPicture {
    margin-top: 10px;

    img {
      width: 334px;
      height: 189px;
    }

    .big-title {
      margin: 10px 0;
      font-size: 16px;
      font-weight: bold;
    }

    .describe {
      font-size: 14px;
      font-weight: 400;
      word-break: break-word;
      color: rgba(34, 34, 34, 0.5);
    }
  }

  .smallPicture {
    margin-top: 16px;

    .small-content {
      display: flex;

      .samll-wraper {
        margin-left: 10px;

        .small-title {
          font-size: 14px;
          font-weight: 600;
          line-height: 16px;
        }

        .small-desc {
          margin-top: 10px;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          color: rgba(34, 34, 34, 0.5);
        }
      }
    }

    img {
      flex-shrink: 0;
      width: 160px;
      height: 90px;
    }
  }

  .materialDownload {
    display: flex;
    align-items: center;
    padding: 10px;
    margin-top: 10px;
    background: #f5f5f5;
    border: 1px solid #e6e6e6;

    .material-title {
      width: 227px;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      margin: 0 26px 0 10px;
    }

    .icon-download2 {
      font-size: 18px;
    }

    .type-icon {
      width: 34px;
      height: 40px;
      background: #fff5f4;
      overflow: hidden;

      &.pdf {
        background: #fff5f4  url("../Common/ResourceItem/assets/imgs/icon_pdf.png") no-repeat;
        background-size: 100% 100%;
        background-position: center;
      }

      &.word {
        background: #f3f7ff url("../Common/ResourceItem/assets/imgs/icon_word.png") no-repeat;
        background-size: 100% 100%;
        background-position: center;
      }

      &.excel {
        background: #f0fcf8 url("../Common/ResourceItem/assets/imgs/icon_excel.png") no-repeat;
        background-size: 100% 100%;
        background-position: center;
      }

      &.mp4 {
        background: #fff9ec url("../Common/ResourceItem/assets/imgs/icon_mp4.png") no-repeat;
        background-size: 100% 100%;
        background-position: center;
      }
    }
  }
}
</style>
