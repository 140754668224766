<template>
  <div :class="[resourceType==='video' ? 'resource-video-wraper' : 'resource-wraper-container']">
    <div v-for="(item, index) in dataList" :key="'resource' + index" :class="[resourceType==='video' ? 'video-content' : 'resource-content']">
      <template v-if="resourceType==='video'">
        <VideoType :data-item="item" v-bind="$props" v-on="$listeners" />
      </template>
      <template v-else>
        <div class="resource-title text-overflow-ellipsis-2">
          {{ item.resourceTitle }}
        </div>
        <div class="resource-wraper" :class="classType">
          <ResourceItem
            v-for="(item1, index1) in item.resourceList" :key="'resourceItem' + index1"
            :resource-type="item1.resourceFormatType" :resource-id="`${item1.resourceId}`"
            :resource-name="item1.buttonName"
            @download="handleDownload" @play="handlePlay"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import mixin from './mixin'
import ResourceItem from '../../Common/ResourceItem/touch'
import VideoType from './VideoType.vue'

export default {
  components: {
    ResourceItem,
    VideoType,
  },
  mixins: [mixin],
  data() {
    return {
      showNum: 6,
    }
  },
}
</script>

<style lang="scss" scoped>
.resource-video-wraper {
  display: flex;
  gap: 17px;
  flex-wrap: wrap;
  margin-bottom: 20px;
  justify-content: space-between;
}

.resource-content {
  padding: 12px;
  margin-bottom: 20px;
  border-radius: 12px;
  background: #fff;
  border: 2px solid #ebebeb;

  .resource-title {
    margin-bottom: 12px;
    font-size: 14px;
    font-weight: bold;
    line-height: 22px;
    color: var(--txt-color-lv1);
  }

  .double-wraper {
    /deep/.resouce-item-container {
      &:nth-child(2n+1) {
        margin-bottom: 12px;
      }
    }
  }
}

/deep/ .resource-item {
  width: 163px !important;
  margin-top: 0 !important;

  .video-img .img {
    width: 163px !important;
    height: 92px !important;
  }

  .video-title {
    width: 163px !important;
  }
}
</style>
