<template>
  <div :class="['common-wrapper', {'has-border': hasBorder}]">
    <div v-if="closeIcon" class="icon-close-mycourses" />
    <div class="recorded-course-card-container font-semibold-new">
      <!-- 订单列表及详情 -->
      <div v-if="isOrder" class="common-recording-card-wrapper isOrder">
        <div class="tag-wrapper font-regular-new">
          <div v-for="(tag, index) in needTags" :key="`tag-${index}`" class="tag text-overflow-ellipsis plain">
            <span>{{ tag }}</span>
          </div>
        </div>
        <div class="card-title">
          <div class="title text-overflow-ellipsis-2">
            {{ cardData.className || cardData.title }}
          </div>
        </div>

        <div v-if="cardData.spec.permanent || cardData.spec.expired || cardData.spec.expireTime" class="recorderd-lesson">
          <i class="iconfont icon-take-class-time" />
          <template v-if=" cardData.spec.permanent">
            {{ recordedConfig.permanentTip }}
          </template>
          <template v-if="cardData.spec.expired">
            {{ recordedConfig.expiredTip || recordedConfig.spec.expiredTip }}
          </template>
          <template v-else-if="cardData.spec.expireTime">
            {{ recordedConfig.courseValidUntil }} {{ cardData.spec.expireTime }}
          </template>
        </div>

        <div class="recorderd-lesson">
          <i class="iconfont icon-session" />
          <slot name="lesson-text">
            {{ cardData.spec && cardData.spec.lessonCount }}
            <template v-if="cardData.spec && cardData.spec.lessonCount == 1">
              {{ recordedConfig.lesson }}
            </template>
            <template v-else>
              {{ recordedConfig.lessons }}
            </template>
          </slot>
        </div>

        <div class="recorderd-lesson">
          <i class="iconfont icon-teacher" />
          <template v-for="(teacher, teacherIndex) in cardData.teachers || cardData.spec.teacherList">
            <span v-if="teacherIndex == 0" :key="'a'+teacherIndex" class="teacher-item">{{ teacher.sysName || teacher.name }}</span>
            <span v-if="teacherIndex > 0" :key="'a'+teacherIndex" class="teacher-item">,&nbsp;{{ teacher.sysName || teacher.name }}</span>
          </template>
        </div>
      </div>
      <!-- 其他 -->
      <template v-else-if="isCommon">
        <div
          :class="['common-recording-card-wrapper', { otherHeight: showDateContent}]"
          :style="{ 'border-radius': borderRadius + 'px' }"
          @click="cardClickHandler"
        >
          <!-- 卡片标识戳 -->
          <div v-if="cardData.expired" :class="{'expired-stamp': cardData.expired}" />
          <div v-if="showDateContent" class="tag-wrapper font-regular-new">
            <div class="tag text-overflow-ellipsis">
              {{ subjectTag }}
            </div>
          </div>
          <div :class="['card-title', {paddingRight: cardData.expired}]">
            <div class="title text-overflow-ellipsis-2">
              {{ cardData.className || cardData.title }}
            </div>
          </div>
          <div v-if="closeIcon" class="close-time-new">
            <i class="iconfont icon-take-class-time" />
            <!-- 价格 -->
            <div v-if="bottomContent === 'price'" class="bottom-content">
              <div v-if="cardData.showOrgPrice > cardData.showPrice" class="old">
                {{ currency }}
                {{
                  cardData.showOrgPrice | priceUnitConvert(minorUnit, isKeepTwoDecimal)
                }}
              </div>
              <div class="current">
                <template v-if="cardData.showPrice == 0">
                  {{ recordedConfig.free }}
                </template>
                <template v-else>
                  {{ currency }}
                  {{ cardData.showPrice
                    || cardData.sellPrice
                    | priceUnitConvert(minorUnit, isKeepTwoDecimal)
                  }}
                </template>
              </div>
            </div>
            <!-- 时间 -->
            <div v-if="bottomContent === 'date'" :class="['bottom-content',{center: bottomContent === 'date'}, {'is-expired': cardData.expired}]">
              <div v-if="!cardData.endDate">
                {{ recordedConfig.permanentTip }}
              </div>
              <div v-if="cardData.expired">
                {{ recordedConfig.expiredTip }}
              </div>
              <div v-else>
                <template v-if="cardData.endDate">
                  <!-- {{ recordedConfig.courseValidUntil }} {{ cardData.endDate }} -->
                  {{ recordedConfig.courseValidUntil }} {{ cardData.endDate | resourceDateFormate(timezone, dateFormat) }}
                </template>
              </div>
            </div>
          </div>
          <div v-if="showDateContent" class="course-time text-overflow-ellipsis">
            <i class="iconfont icon-take-class-time" />
            <span class="schedule-text">
              <template v-if="cardData.permanent">
                {{ recordedConfig.permanentTip }}
              </template>
              <template v-if="cardData.expired">
                {{ recordedConfig.expiredTip }}
              </template>
              <template v-else>
                <template v-if="cardData.expirationTime">{{ recordedConfig.courseValidUntil }} {{ cardData.expirationTime }}</template>
              </template>
            </span>
          </div>
          <!-- tag 和 讲次 -->
          <!-- <template v-else>
            <ul class="label-list">
              <li class="label-item subject">
                {{ subjectTag }}
              </li>
              <li v-if="cardData.spec" class="label-item lesson-total">
                {{ cardData.spec.lessonCount || 0 }}
                <template v-if="cardData.spec.lessonCount == 1">
                  {{ recordedConfig.lesson }}
                </template>
                <template v-else>
                  {{ recordedConfig.lessons }}
                </template>
              </li>
            </ul>
          </template> -->
          <template>
            <div class="can-scroll-teachers">
              <div class="teachers-list">
                <div
                  v-for="(teacher, index) in cardData.teachers || cardData.spec.teacherList"
                  :key="'teacher-' + index"
                  class="teacher-information"
                >
                  <template v-if="teacher">
                    <div class="avatar">
                      <img v-lazy-load :data-src="teacher.avatar" alt="">
                    </div>
                    <div class="describe">
                      <div class="name text-overflow-ellipsis">
                        {{ teacher.name || teacher.sysName }}
                      </div>
                      <div class="position text-overflow-ellipsis">
                        {{ getTeacherType(teacher.teacherType || teacher.identityType) }}
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div
          v-if="hasBottom && !closeIcon"
          class="bottom-summary text-overflow-ellipsis"
        >
          <div class="line" />
          <!-- 价格 -->
          <div v-if="bottomContent === 'price'" class="bottom-content">
            <div v-if="cardData.showOrgPrice > cardData.showPrice" class="old">
              {{ currency }}
              {{
                cardData.showOrgPrice | priceUnitConvert(minorUnit, isKeepTwoDecimal)
              }}
            </div>
            <div class="current">
              <template v-if="cardData.showPrice == 0">
                {{ recordedConfig.free }}
              </template>
              <template v-else>
                {{ currency }}
                {{ cardData.showPrice
                  || cardData.sellPrice
                  | priceUnitConvert(minorUnit, isKeepTwoDecimal)
                }}
              </template>
            </div>
          </div>
          <!-- 时间 -->
          <div v-if="bottomContent === 'date'" :class="['bottom-content',{center: bottomContent === 'date'}, {'is-expired': cardData.expired}]">
            <div v-if="!cardData.endDate">
              {{ recordedConfig.permanentTip }}
            </div>
            <div v-if="cardData.expired">
              {{ recordedConfig.expiredTip }}
            </div>
            <div v-else>
              <template v-if="cardData.endDate">
                {{ recordedConfig.courseValidUntil }} {{ cardData.endDate }}
              </template>
            </div>
          </div>
        </div>
      </template>
      <div v-else class="common-recording-card-wrapper font-medium-new"
           :class="{ 'clickable': clickable}"
           :style="{ 'border-radius': borderRadius + 'px' }"
           @click="cardClickHandler"
      >
        <div class="tag-wrapper font-regular-new">
          <div class="tag text-overflow-ellipsis">
            {{ subjectTag }}
          </div>
        </div>
        <!-- title -->
        <div class="card-title">
          <div class="title text-overflow-ellipsis-2">
            {{ cardData.title }}
          </div>
        </div>
        <!-- 讲次 -->
        <div class="lesson-num">
          <slot name="lesson-text">
            {{ cardData.spec && cardData.spec.leftLesson }}
            <template v-if="cardData.spec && cardData.spec.leftLesson == 1">
              {{ recordedConfig.lesson }}
            </template>
            <template v-else>
              {{ recordedConfig.lessons }}
            </template>
          </slot>
        </div>
        <!-- 教师 -->
        <div class="teacher-list">
          {{ recordedConfig.teacher }}
          <span v-for="(teacher, index) in allTeacherList" :key="'teacher-' + index" class="name text-overflow-ellipsis">
            {{ teacher.sysName }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import mixin from './mixin'

export default {
  mixins: [mixin],
  props: {
    isCommon: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.common-wrapper {
  position: relative;
  background: #fff;
  border-radius: 4px;
  // border: 1px solid #e7e7e7;

  &.has-border {
    border: 1px solid #e7e7e7;
  }

  .close-time-new {
    display: flex;
    margin: 4px 0 0;
    align-items: center;

    .iconfont {
      width: 14px;
      color: #d1d5dc;
      margin: 0 8px 0 0;
      font-size: 14px;

      &.icon-take-class-time {
        opacity: 1 !important;
        vertical-align: top !important;
      }
    }

    .bottom-content {
      flex: 1;
      color: var(--txt-color-lv2);
      line-height: 18px;
      font-weight: normal;
    }
  }
}

.recorded-course-card-container {
  position: relative;
  z-index: 1;
  // border: 1px solid #e7e7e7;
  border-radius: 4px;

  ul,
  li {
    list-style: none;
  }

  .tag-wrapper {
    // margin-bottom: 10px;
    overflow: hidden;
    display: inline-block;
    vertical-align: -11px;

    .tag {
      display: inline-block;
      height: 20px;
      line-height: 20px;
      color: #fff;
      font-size: 12px;
      padding: 0 6px;
      margin-right: 3px;
      background: var(--txt-color-link);
      border-radius: 4px;
    }
  }
}

.icon-close-mycourses {
  width: 60px;
  height: 60px;
  position: absolute;
  background: url("~assets/images/my-courses/icon_close.png") 0 0/ 100% auto no-repeat;
  bottom: 0;
  right: 0;
}

.common-recording-card-wrapper {
  position: relative;
  padding: 16px;
  font-size: 12px;
  // border: 1px solid #e7e7e7;

  .lesson-num,
  .name {
    font-weight: normal;
    color: --txt-color-lv2;
  }

  .recorderd-lesson {
    display: flex;
    align-items: flex-start;
    padding: 10px 0 0;
    font-size: 12px;
    line-height: 14px;
    color: var(--txt-color-lv2);

    .iconfont {
      color: #d1d5dc;
      margin-right: 5px;
    }

    .label {
      margin-right: px2vw(16);
    }
  }

  .tag-new {
    height: 20px;
    line-height: 20px;
    padding: 0 4px;
    font-size: 12px;
    font-family: PingFangSC-Semibold, PingFang SC, Arial, Helvetica, sans-serif;
    font-weight: 600;
    color: #fff;
    background: var(--txt-color-link);
    border-radius: 4px;
    // display: inline-block;
    vertical-align: top;
    margin: 0 10px 0 0;
  }

  // 订单列表及详情
  &.isOrder {
    .text-wrapper {
      color: var(--txt-color-lv2);
      line-height: 16px;
      margin-top: 10px;

      .name {
        margin-right: 10px;
      }

      .time-text {
        margin-left: 8px;
      }
    }
  }

  &.otherHeight {
    height: auto;
  }

  .course-time {
    font-size: px2vw(24);
    color: #999;
    font-weight: 500;
    // margin-bottom: 14px;
    margin-top: 10px;
  }

  .expired-stamp {
    position: absolute;
    top: px2vw(0);
    right: px2vw(0);
    width: px2vw(120);
    height: px2vw(104);
    background-image: url("~assets/images/my-courses/expired.png");
    background-size: cover;
    background-position: center;
    z-index: 1;
  }

  .card-title {
    display: inline-flex;
    color: var(--txt-color-lv1);
    // height: 38px;
    align-items: center;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC, Arial, Helvetica, sans-serif;
    font-weight: 600;
    line-height: 22px;
    // width: 280px;
    &.paddingRight {
      padding-right: 40px;
    }

    .title {
      max-height: 100%;
      vertical-align: middle;
    }
  }

  .order-main-title {
    .tag-container {
      margin-bottom: 8px;
      display: flex;
    }

    .tag {
      display: inline-block;
      margin-right: 2.66667vw;
      padding: 1.06667vw 2.13333vw;
      font-size: 3.2vw;
      line-height: 3.73333vw;
      font-weight: 500;
      color: var(--txt-color-link);
      border: 0.26667vw solid rgba(255, 170, 10, 0.4);
      border-radius: 3.73333vw;
      background-color: #fff5e3;

      .iconfont {
        font-size: 3.2vw;
        line-height: 2.66667vw;
        transform: scale(0.833);
        vertical-align: middle;
      }

      span {
        vertical-align: middle;
      }
    }

    .title {
      height: 37px;
      font-size: 16px;
      color: var(--txt-color-lv1);
      line-height: 19px;
      font-weight: 600;
      overflow: hidden;
      display: flex;
      align-items: center;

      div {
        max-height: 100%;
      }
    }
  }

  .label-list {
    margin: 14px 0;
    display: flex;
    align-items: center;
    padding-left: 0;

    .label-item {
      height: 22px;
      padding: 0 8px;
      margin-right: 10px;
      background: var(--txt-color-link3);
      border-radius: 11px;
      color: var(--txt-color-link);
      line-height: 22px;
      font-weight: 500;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .teacher-information {
    width: 155px;
    margin-right: 22px;
    display: flex;

    &:last-of-type {
      margin-right: 0;
    }

    .avatar {
      width: 38px;
      height: 38px;
      flex: 0 0 38px;
      margin-right: 8px;
      background: linear-gradient(180deg, #f1f3f5 0%, #dee2e7 100%);
      border-radius: 100%;
      border: 1px solid #e3e5e9;
      overflow: hidden;
      font-size: 0;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .describe {
      width: 100%;
      line-height: 14px;
      overflow: hidden;

      .name {
        margin-top: 4px;
        margin-bottom: 2px;
        color: var(--txt-color-lv1);
        font-weight: 600;
      }

      .position {
        width: fit-content;
        color: var(--txt-color-lv2);
      }
    }
  }

  .can-scroll-teachers {
    padding-top: 12px;

    .teachers-list {
      display: flex;
      align-items: center;
      overflow-x: auto;
    }
  }

  .is-expired {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 17;
  }
}

.bottom-summary {
  font-size: 14px;
  line-height: 54px;
  text-align: right;
  padding: 0 20px;

  .line {
    width: 100%;
    border-top: 1px solid #fafafa;
  }

  &.is-expired {
    // background: linear-gradient(90deg, #fff 0%, #f4f6fa 100%);
    color: var(--txt-color-lv2);
  }

  .bottom-content {
    color: var(--txt-color-link);
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC, Arial, Helvetica, sans-serif;
    font-weight: 400;
    line-height: 17px;
    padding: 12px 0;
    text-align: left;

    // &.center {
    //   justify-content: center;
    // }

    // &.linearGradient {
    //   // background: linear-gradient(90deg, #fff 0%, #fff9ec 100%);
    // }

    // &.is-expired {
    //   // background: linear-gradient(90deg, #fff 0%, #f4f6fa 100%);
    //   color: var(--txt-color-lv2);
    // }

    .old {
      height: 16px;
      margin-right: 12px;
      font-size: 16px;
      color: var(--txt-color-lv2);
      text-decoration: line-through;
    }

    .current {
      height: 20px;
      font-size: 20px;
      color: var(--txt-color-link);
    }
  }
}

</style>
