<template>
  <div class="category-container">
    <div v-if="title" class="category-title">
      {{ title }}
    </div>
    <div id="channel-tab-filter" class="filter-wrapper font-medium">
      <div v-show="boardShow" class="board-wrap">
        <div class="category-list">
          <div v-for="(item, index) in category" :key="index"
               v-sa-track="[{type: 'click', event: 'component_button_click', data: getCategoryClickTrackData(item.name)}]"
               class="category-item text-overflow-ellipsis"
               :class="{'actived': selectedIndex == index}"
               @click="handleClick(index)"
          >
            {{ item.name }}
          </div>
          <div class="retract-btn">
            <i class="iconfont icon-arrow-top2" @click="boardShow = !boardShow" />
          </div>
        </div>
      </div>
      <div v-show="!boardShow" class="tab-wrap">
        <div class="tabs-container">
          <van-tabs
            v-show="!boardShow"
            v-model="selectedIndex"
            class="column-filter-container"
            type="card"
            color="#ff9f0a"
            :ellipsis="false"
            @click="handleClick"
          >
            <van-tab
              v-for="(item, index) in category"
              :key="index"
              :name="index"
              title-class="filter-tab"
            >
              <template #title>
                <div v-sa-track="[{type: 'click', event: 'component_button_click', data: getCategoryClickTrackData(item.name)}]">
                  {{ item.name }}
                </div>
              </template>
            </van-tab>
          </van-tabs>
        </div>
        <div class="open-btn">
          <i class="iconfont icon-arrow-bottom2" @click="boardShow = !boardShow" />
        </div>
      </div>
    </div>
    <div class="result-wrapper">
      <template v-if="loadingModules">
        <Loading :show="true" />
      </template>
      <template v-else>
        <template v-if="moduleList.length">
          <DynamicComponent v-if="!!moduleList.length" :style="handleStyles(moduleList)" :locale="locale" :component-list="moduleList" />
        </template>
        <template v-else>
          <div class="exception-wrapper">
            <Exception :tip-text="langContext.emptyNotice" placeholder-class-name="no-avalible-data-img" />
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import mixin from './mixin'
import Loading from 'components/common/Loading'
import Exception from 'components/common/Exception'

export default {
  name: 'Category',
  components: {
    Loading,
    Exception,
    DynamicComponent: () => import('../touch'),
  },
  mixins: [mixin],
  data() {
    return {
      boardShow: false,
      selectedIndexCache: 0,
    }
  },
  mounted() {
    if (this.targetType === 'module') {
      this.initQueryModule()
    }
  },
  methods: {
    handleStyles(list = []) {
      const componentList = list.filter((item) => item.type !== 49)
      const styles = {
        paddingBottom: '5.33333vw',
        background: '#fff',
      }
      if (!componentList.length) {
        return {
          ...styles,
        }
      }
      const firstComponent = componentList[0]
      if (!firstComponent.content) {
        return {
          ...styles,
        }
      }
      const contentData = JSON.parse(firstComponent.content)
      const styleAttr = {}
      if (!contentData.componentName.includes('Banner')) {
        styleAttr.paddingTop = '4.26667vw'
      }
      return {
        ...styleAttr,
        ...styles,
      }
    },
    /**
     * 初始查询模块列表数据
     */
    initQueryModule() {
      let moduleList = []
      try {
        moduleList = this.category[0].moduleList
      } catch (error) {
        console.log(error)
      }
      this.queryModuleList(moduleList)
    },

    /**
     * 点击分类
     * @param {Number} index 索引
     */
    handleClick(index) {
      const item = this.category[index]
      if (this.targetType === 'page') {
        window.open(item.pageUrl, '_blank')
        return
      }
      if (index === this.selectedIndexCache) return
      this.selectedIndex = index
      this.selectedIndexCache = index
      this.queryModuleList(item.moduleList)
      this.boardShow = false
    },
  },
}
</script>

<style lang="scss" scoped>
.category-title {
  font-size: 12px;
  font-weight: bold;
  padding: 14px 16px 12px 16px;
}

.filter-wrapper {
  // margin-bottom: 35px;

  .tab-wrap {
    display: flex;
    justify-content: center;
    position: relative;
    padding-right: 16px;

    .tabs-container {
      flex: 1;
      width: 313px;
      height: 38px;
      padding: 4px 0;
      border-right: 1px solid #ebebeb;

      /deep/.van-tabs__nav {
        background: none;
      }

      /deep/.van-tabs__nav--card {
        border: none;
        margin: 0;

        .filter-tab {
          height: 30px;
          padding: 0 10px;
          border-color: #ebebeb !important;
          border-style: solid;
          border-width: 1px;
          border-radius: 15px;
          margin-right: 8px;
          background-color: #fff;

          .van-tab__text > div {
            max-width: 128px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          &:first-child {
            margin-left: 16px;
          }
        }

        .van-tab__text {
          color: #262626;
          font-size: 12px;
        }

        .van-tab--active {
          border-color: var(--txt-color-link) !important;

          .van-tab__text {
            color: #fff;
          }
        }
      }
    }
  }

  .board-wrap {
    padding: 14px 16px 6px 16px;

    .category-list {
      display: flex;
      flex-wrap: wrap;
    }

    .category-item {
      max-width: 148px;
      height: 30px;
      line-height: 28px;
      padding: 0 10px;
      margin: 0 12px 10px 0;
      background: #fff;
      border-radius: 16px;
      font-size: 12px;
      color: #262626;
      border: 1px solid #ebebeb;

      &.actived {
        background: var(--txt-color-link);
        color: #fff;
        border-color: var(--txt-color-link);
        font-weight: 500;
      }
    }
  }

  .retract-btn,
  .open-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background: #fff;
    border: 1px solid #ebebeb;
    border-radius: 15px;

    i {
      font-size: 12px;
      transform: scale(0.8);
      color: #262626;
    }
  }

  .open-btn {
    margin: 4px 0 0 15px;
  }
}

.exception-wrapper {
  padding: 15vh 0 0;
}
</style>
