<template>
  <div class="customer-form-wrapper">
    <div v-if="title || intro" class="form-head">
      <div v-if="title" class="title" v-text="title" />
      <div v-if="intro" class="description" v-text="intro" />
    </div>
    <div class="form-body">
      <van-form
        ref="formData"
        @submit="handleSubmit"
      >
        <template v-for="(item, index) in formData.dataList">
          <div :key="index" class="form-item">
            <template v-if="item.type === 'input'">
              <van-field
                v-model="item.value"
                :placeholder="item.name"
                :rules="item.rules"
                class="item-field"
              />
            </template>
            <template v-else-if="item.type === 'select'">
              <template v-if="item.multiple">
                <van-field
                  readonly
                  clickable
                  name="picker"
                  :value="item.valueName"
                  :rules="item.rules"
                  :placeholder="item.name"
                  class="item-field"
                  @click="item.showPopup = true"
                />
                <div class="iconfont icon-bottom-dark" />
                <BottomPopup :show="item.showPopup" @right-icon-handler="onPopupConfirm(index)" @left-icon-handler="item.showPopup = false">
                  <template slot="custom-body">
                    <div class="checkbox-wrapper">
                      <van-checkbox-group v-model="item.checked" class="checkbox-wrap" checked-color="#FFAA0A" shape="square">
                        <van-cell
                          v-for="(option, optionIndex) in item.options"
                          :key="optionIndex"
                          clickable
                          :title="option.name"
                          @click="checkboxToggle(index, optionIndex)"
                        >
                          <template #right-icon>
                            <van-checkbox :ref="'checkboxes' + index" :name="optionIndex" />
                          </template>
                        </van-cell>
                      </van-checkbox-group>
                    </div>
                  </template>
                </BottomPopup>
              </template>
              <template v-else>
                <van-field
                  readonly
                  clickable
                  name="picker"
                  :value="item.valueName"
                  :rules="item.rules"
                  :placeholder="item.name"
                  class="item-field"
                  @click="item.showPopup = true"
                />
                <div class="iconfont icon-bottom-dark" />
                <van-popup v-model="item.showPopup" position="bottom">
                  <van-picker
                    :ref="'Picker' + index"
                    show-toolbar
                    value-key="name"
                    :columns="item.options"
                    @confirm="onPickerConfirm(index)"
                    @cancel="item.showPopup = false"
                  />
                </van-popup>
              </template>
            </template>
          </div>
        </template>
      </van-form>
      <div class="button-wrapper">
        <van-button round type="primary" class="button btn-submit" :text="submitName" :loading="loading" @click="onSubmit" />
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 自定义表单
 */
import mixin from './mixin'
import BottomPopup from '@/components/touch/common/BottomPopup'

export default {
  name: 'CustomerForm',
  components: {
    BottomPopup,
  },
  mixins: [mixin],
  data() {
    return {}
  },
  methods: {
    onSubmit() {
      this.$refs.formData.submit()
    },

    handleSubmit() {
      this.save({
        successCallback: () => {
          this.$Notify({ type: 'success', message: this.successNotice })
        },
        failCallback: (msg) => {
          this.$Notify({ type: 'warning', message: msg })
        },
      }, {
        sourceCode: 'touch',
        mediaCode: 'touch_lead',
      })
    },

    onPickerConfirm(index) {
      const [value] = this.$refs[`Picker${index}`][0].getIndexes()
      const options = this.$refs[`Picker${index}`][0].getValues()
      const valueName = options.map((option) => option.name)
      this.formData.dataList[index].value = value
      this.formData.dataList[index].valueName = valueName.join(',')
      this.formData.dataList[index].showPopup = false
    },

    onPopupConfirm(index) {
      const { checked } = this.formData.dataList[index]
      const options = checked.map((checkedIndex) => this.formData.dataList[index].options[checkedIndex].name)
      this.formData.dataList[index].value = checked
      this.formData.dataList[index].valueName = options.join(',')
      this.formData.dataList[index].showPopup = false
    },

    checkboxToggle(index, optionIndex) {
      this.$refs[`checkboxes${index}`][optionIndex].toggle()
    },
  },
}
</script>

<style lang="scss" scoped>
.customer-form-wrapper {
  padding: 20px 20px 0 20px;
}

.form-head {
  .title {
    line-height: 30px;
    color: var(--txt-color-lv1);
    font-size: 24px;
    text-align: center;
  }

  .description {
    margin-top: 20px;
    font-size: 14px;
    color: var(--txt-color-lv1);
    line-height: 20px;
    text-align: center;
  }
}

.form-body {
  margin-top: 16px;

  .form-item {
    position: relative;

    .icon-bottom-dark {
      position: absolute;
      right: 20px;
      top: 12px;
      color: #171717;
      font-size: 22px;
    }
  }

  .item-field {
    padding: 10px 16px;
    margin-bottom: 10px;
    border: 1px solid #e7e9ed;
    border-radius: 8px;
    overflow: visible;

    /deep/.van-field__control {
      padding-right: 20px;
      font-weight: 500;
      height: 30px;
      line-height: 30px;
      color: var(--txt-color-lv1);

      &::placeholder {
        font-size: 16px;
        font-weight: 500;
        color: #676769;
        line-height: 30px;
      }
    }

    &::after {
      border-bottom: none;
    }

    &.van-field--error {
      margin-bottom: 35px;

      /deep/.van-field__control,
      /deep/.van-field__control::placeholder {
        color: var(--txt-color-lv2) !important;
      }
    }

    /deep/ .van-field__error-message {
      position: absolute;
      top: 45px;
    }
  }

  .button-wrapper {
    margin-top: 30px;
    text-align: center;

    .btn-submit {
      font-weight: bold;
      line-height: 50px;
      width: 285px;
      height: 50px;
      background: var(--txt-color-link);
      border-radius: 25px;

      /deep/ .van-button__text {
        font-size: 16px;
      }
    }
  }
}

.checkbox-wrapper {
  background: #fff;
  padding: 0 10px;
}
</style>
