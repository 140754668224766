<template>
  <div class="rich-text-column-wrapper">
    <div v-if="title" class="headline">
      {{ title }}
    </div>
    <div class="content-box">
      <div v-for="(item, index) in dataList" :key="'rich-'+index" class="card-content">
        <div v-html="item.content" />
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 富文本分栏组件
 */
import mixin from './mixin'

export default {
  name: 'RichTextColumn',
  mixins: [mixin],
}
</script>
<style lang="scss" scoped>
  @import './common.scss';

  .rich-text-column-wrapper {
    padding: 0 16px;

    .headline {
      max-height: 60px;
      font-size: 22px;
      line-height: 30px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      // white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .content-box {
      padding: 10px;

      /deep/.card-content {
        padding: 10px;
        margin-bottom: 10px;
        overflow-x: scroll;

        &:last-of-type {
          margin-bottom: 0;
        }

        ul,
        ol {
          padding-left: 20px;
        }
      }
    }
  }
  </style>
