<template>
  <div class="resource-container">
    <div class="resource-header">
      <div class="resource-titile text-overflow-ellipsis-2">
        {{ title }}
      </div>
      <div class="resource-desc text-overflow-ellipsis-3">
        {{ description }}
      </div>
    </div>
    <div :class="{'expanse-content': resourceType === 'single'}">
      <ResourceWraper
        :data-list="showMore ? dataList.slice(0, showMoreNum) : dataList" :resource-type="resourceType"
        @download="clickDownload" @play="handlePlay"
      />
    </div>
    <div v-if="isShowMore" class="expanse-container" :style="{'height': expanseHeight}">
      <div ref="expanseContainer" :class="{'expanse-content': resourceType === 'single'}">
        <ResourceWraper
          :data-list="dataList.slice(showMoreNum)" :resource-type="resourceType"
          @download="clickDownload" @play="handlePlay"
        />
      </div>
    </div>
    <div v-if="isShowMore" class="expanse-bottom">
      <div class="bottom-more" @click="handleExpanse">
        <div class="iconfont icon-double-arrow-top" :class="{'icon-reverse': !isExpanse}" />
        <span class="expanse-text">{{ isExpanse ? langContext.retract : langContext.more }}</span>
      </div>
    </div>
    <VideoPopup ref="videoPopup" />
  </div>
</template>

<script>
import { oneApi } from 'config/api'
import mixin from './mixin'
import trackReportMixin from '../Common/CommonMixin/trackReport'
import { resourceUrl } from 'common/utils.js';
import ResourceWraper from './ResourceWraper/touch'
import VideoPopup from 'components/common/VideoPopup/index';

export default {
  components: {
    ResourceWraper,
    VideoPopup,
  },
  mixins: [mixin, trackReportMixin],
  data() {
    return {
      marginHeight: 20, // margin高度
      resourceUrl: resourceUrl(),
      isApp: null,
      openWebview: true,
      showMoreNum: 6, // 默认查看更多数量
    }
  },
  mounted() {
    this.isApp = !!window.JsInjectionActivity
  },
  methods: {
    // 展示所有筛选项
    handleExpanse() {
      this.expanseHeight = this.isExpanse ? 0 : `${this.$refs.expanseContainer.offsetHeight + this.marginHeight}px`
      this.isExpanse = !this.isExpanse
    },
    clickDownload(params) {
      if (this.isApp) {
        this.getResource(params)
      } else {
        this.handleDownload(params)
      }
    },
    async handleDownload({ id, type, name }) {
      try {
        this.buttonTrackReport({ buttonName: name })
      } catch (error) {
        console.log('buttonTrackReport handleDownload', error)
      }
      const materialsClickNum = window.localStorage.getItem('materialsClickNum') || 0
      window.localStorage.setItem('materialsClickNum', Number(materialsClickNum) + 1)

      if (!this.isLogin && Number(materialsClickNum) >= this.allowMaterialsNum) {
        this.nologin()
        return
      }
      // const userStatus = await this.checkUserStatus()
      // if (!userStatus) return
      const resData = await this.getResourceData(id)
      if (!resData) return
      // gtm 资料组件下载埋点
      if (this.$store.state.locale === 'us' || this.$store.state.locale === 'hk') {
        window.dataLayer.push({
          event: 'download_resources_gtm',
        });
      }
      this.$router.push({ name: 'resources-view-id', params: { id }, query: { resource: this.resourceUrl + resData.url, type, name: resData.fileName } })
    },
    async getResource({ id, name, type }) {
      const materialsClickNum = window.localStorage.getItem('materialsClickNum') || 0
      window.localStorage.setItem('materialsClickNum', Number(materialsClickNum) + 1)

      if (!this.isLogin && Number(materialsClickNum) >= this.allowMaterialsNum) {
        const currentFullPath = this.$route.fullPath
        const backUrl = encodeURI(currentFullPath)
        this.$login(`/login?cancelUrl=${backUrl}&successUrl=${backUrl}&triggerSource=down`, null, '资料站')
        return
      }
      // const info = await this.$axios.post(oneApi.queryUserBasicInfo, { fillInAttention: true }, { rewritePostBody: true })
      // if (!info.data.email) {
      //   this.$store.dispatch('bindDialog/setDialogData', {
      //     visible: true,
      //     type: 'email',
      //   })
      //   return
      // }
      // if (!info.data.phone) {
      //   this.$store.dispatch('bindDialog/setDialogData', {
      //     visible: true,
      //     type: 'phone',
      //   })
      //   return
      // }
      const res = await this.$axios.post(oneApi.getUrl, id, { rewritePostBody: true });
      if (Number(res.code) !== 0) {
        this.$Notify({ type: 'warning', message: res.msg })
        return
      }
      const resData = res.data || {}
      const nuxtLink = `/resources/view/${id}?resource=${this.resourceUrl + resData.url}&type=${type}&name=${resData.fileName}`
      if (this.openWebview) {
        this.$transformLocalPath(nuxtLink, this.openWebview, this.$router)
      } else {
        this.$router.push(nuxtLink)
      }
      const data = { ...this.saData, button_name: name }
      this.Sensors.track('resources_card_click', data)
      this.btnsShow = false
    },
    async handlePlay({ id, name }) {
      try {
        this.buttonTrackReport({ buttonName: name })
      } catch (error) {
        console.log('buttonTrackReport handlePlay', error)
      }
      const materialsClickNum = window.localStorage.getItem('materialsClickNum') || 0
      window.localStorage.setItem('materialsClickNum', Number(materialsClickNum) + 1)

      if (!this.isLogin && Number(materialsClickNum) >= this.allowMaterialsNum) {
        if (this.isApp) {
          const currentFullPath = this.$route.fullPath
          const backUrl = encodeURI(currentFullPath)
          this.$login(`/login?cancelUrl=${backUrl}&successUrl=${backUrl}`, null, '资料站')
          return
        }
        this.nologin()
        return
      }
      // const userStatus = await this.checkUserStatus()
      // if (!userStatus) return
      const resData = await this.getResourceData(id)
      if (!resData) return
      this.$refs.videoPopup.handleOpen(resData.signedUrl)
    },
    nologin() {
      const currentFullPath = this.$route.fullPath
      const backUrl = encodeURI(currentFullPath)
      this.$router.replace(`/login?&cancelUrl=${backUrl}&successUrl=${backUrl}`);
    },
  },
}
</script>

<style lang="scss" scoped>
.resource-container {
  padding: 20px 16px;
  background: #fff url('./assets/imgs/touch_bg.png') no-repeat;
  background-size: 146px 216px;
  background-position: 100% 20px;

  .resource-header {
    color: var(--txt-color-link);
    padding: 24px 0 40px;

    .resource-titile {
      margin-bottom: 10px;
      font-size: 24px;
      font-weight: bold;
      line-height: 32px;
    }

    .resource-desc {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }

  .expanse-container {
    height: 0;
    overflow: hidden;
    transition: height 0.4s;
  }

  .expanse-bottom {
    display: flex;
    justify-content: center;

    .bottom-more {
      display: flex;
      align-items: center;
      color: rgba(0, 0, 0, 0.6);
      cursor: pointer;

      .icon-double-arrow-top {
        padding-top: 3px;
        font-size: 14px;
        transform: scale(0.7);
      }

      .icon-reverse {
        transform: rotate(180deg) scale(0.7);
      }

      .expanse-text {
        margin-left: 10px;
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
      }
    }
  }
}
</style>
