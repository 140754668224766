<template>
  <div class="ad3-bg" v-lazy-background lazy-background="./assets/imgs/comp-ad3-bg-touch.png">
    <ModuleTextHeader :title="title" :describe="describe" />
    <div class="container">
      <img v-if="dataList.length === 1" v-lazy-load v-lazy-load :data-src="dataList[0].imageUrl" alt="">
      <div v-else class="swiper-bg">
        <van-swipe class="my-swipe" :autoplay="2500" indicator-color="#ff9f0a" @change="changeSwiperIndex">
          <van-swipe-item v-for="(item, index) in dataList" :key="index">
            <img v-lazy-load v-lazy-load :data-src="item.imageUrl" alt="">
            <img v-if="item.videoUrl" v-lazy-load class="container-play" v-lazy-load data-src="./assets/imgs/play.png" alt="" @click="handleToPlay">
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
    <div v-if="buttonName && skipType != 1" class="button btn-wraper" @click="buttonTrackReport({ buttonName })">
      <DiffLinkLabel :url="buttonUrl" :target="linkTarget">
        <div class="ad3-btn text-overflow-ellipsis">
          {{ buttonName }}
        </div>
      </DiffLinkLabel>
    </div>
    <div v-else-if="buttonName" class="button btn-wraper" @click="toGn(buttonName)">
      <div class="ad3-btn text-overflow-ellipsis">
        {{ buttonName }}
      </div>
    </div>
    <VideoPopup ref="videoPopup" />
  </div>
</template>

<script>
/**
 * 广告样式（三）
 */
import mixin from './mixin'
import trackReportMixin from '../Common/CommonMixin/trackReport'
import linkTargetMixin from '../Common/CommonMixin/linkTarget'
import ModuleTextHeader from '../Common/ModuleTextHeader/touch.vue';
import VideoPopup from 'components/common/VideoPopup/index';
import DiffLinkLabel from '../Common/DiffLinkLabel/touch.vue'

export default {
  name: 'Ad3',
  components: {
    ModuleTextHeader,
    VideoPopup,
    DiffLinkLabel,
  },
  mixins: [mixin, trackReportMixin, linkTargetMixin],
  methods: {
    getbackgroundImage() {
      const url = this.dataList.length ? this.dataList[0].imageUrl : ''
      return {
        backgroundImage: `url(${url})`,
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
      }
    },
    toGn(buttonName) {
      this.buttonTrackReport({ buttonName })
      if (!this.isLogin) {
        const currentFullPath = this.$route.fullPath
        const backUrl = encodeURI(currentFullPath)
        this.$router.replace(`/login?cancelUrl=${backUrl}&successUrl=${backUrl}&triggerSource=appointment`)
        return
      }
      const currentFullPath = this.$route.fullPath
      const backUrl = encodeURI(currentFullPath)
      this.$router.replace(`/appointmentDiagnosis?successUrl=${backUrl}`);
    },
  },
}
</script>
<style lang="scss" scoped>
/deep/.van-swipe__indicators {
  position: relative;
  left: 0;
  top: 0;
  transform: translateX(0);
  display: flex;
  justify-content: center;
  margin-top: 16px;

  .van-swipe__indicator {
    width: 6px;
    height: 6px;
    background-color: rgba(0, 0, 0, 0.1);

    &:not(:last-child) {
      margin-right: 10px;
    }
  }

  .van-swipe__indicator--active {
    background: var(--txt-color-link);
  }
}

.ad3-bg {
  padding: 40px 20px 30px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.container {
  position: relative;
  padding: 9px;
  background-color: #fff;
  border-radius: 19px;

  .swiper-bg {
    width: 318px;
    height: 222px;
  }

  img {
    width: 318px;
    height: 222px;
    border-radius: 11px;
  }

  .container-play {
    width: 50px;
    height: 50px;
    position: absolute;
    cursor: pointer;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }
}

.btn-wraper {
  display: block;
  width: 285px;
  margin: 26px auto 0;
  padding: 10px 20px;
  font-size: 16px;
  line-height: 30px;
  font-weight: bold;
  background: var(--txt-color-link);
  border-radius: 32px;

  .ad3-btn {
    color: #fff;
  }
}
</style>
