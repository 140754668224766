<template>
  <div class="module-rich-text">
    <div class="title font-semibold">
      {{ title }}
    </div>
    <div class="content quote typo" v-html="content" />
  </div>
</template>

<script>
/**
 * 图文（二）web组件
 */
import mixin from './mixin'

export default {
  name: 'ModuleRichText',
  mixins: [mixin],
}
</script>

<style lang="scss">
@import "assets/css/typo.scss";
</style>

<style lang="scss" scoped>
.module-rich-text {
  padding: 0 20px 20px 20px;

  .title {
    position: relative;
    margin-bottom: 16px;
    font-size: 18px;
    color: var(--txt-color-lv1);
    padding-left: 14px;

    &::before {
      display: block;
      position: absolute;
      left: 0;
      top: 3px;
      content: " ";
      width: 4px;
      height: 22px;
      background: url('./assets/title-line.png') no-repeat;
      background-size: 4px 22px;
    }
  }

  .content {
    padding: 23px 26px 26px;
    font-size: 14px;
    color: var(--txt-color-lv3);
    line-height: 24px;
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #f1f1f1;
    word-wrap: break-word;
    &.quote {
      background: url('./assets/quote-left-top.png') no-repeat 12px 6px, url('./assets/quote-right-bottom.png') no-repeat right 12px bottom 6px;
      background-size: 27px 16px, 27px 16px;
      background-color: #fff;
    }
  }
}
</style>
