<template>
  <div class="comment-container wrapper" v-lazy-background lazy-background="./assets/imgs/comments-touch.png">
    <ModuleTextHeader :title="title" :describe="describe" />
    <div class="content">
      <div ref="mySwiper" v-swiper:mySwiper="swiperOption" class="swiper-container">
        <div class="swiper-wrapper">
          <div
            v-for="(item, index) in dataList"
            :key="index"
            class="swiper-slide"
          >
            <TextAroundImg
              :image-url="item.imageUrl"
              :title="item.title"
              :sub-title="item.subtitle"
              :describe="item.describe"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import mixin from './mixin'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import ModuleTextHeader from '../Common/ModuleTextHeader/touch.vue'
import TextAroundImg from './TextAroundImg/touch.vue'

export default {
  name: 'UserComments',
  /* eslint-disable-next-line */
  components: { ModuleTextHeader, TextAroundImg, Swiper, SwiperSlide,},
  directives: {
    swiper: directive,
  },
  mixins: [mixin],
  data() {
    return {
      swiperOption: {
        slidesPerView: 'auto',
        loop: true,
        lazy:{
          loadPrevNext:true, // 预加载前一个后一个
        },
        watchSlidesProgress: true,
        watchSlidesVisibility: true,
        preloadImages: false,
        loopAdditionalSlides: 2,
        loopedSlides: 5,
        autoplay: {
          delay: 2000,
          disableOnInteraction: false,
        },
        speed: 8000,
        autoplayDisableOnInteraction: true,
        observer: true,
        observeParents: true,
        observeSlideChildren: true,
      },
    }
  },
}

</script>
<style lang="scss" scoped>
.comment-container {
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .content {
    padding-top: 10px;
  }

  .swiper-container {
    .swiper-slide {
      width: 320px;
      height: 440px;
      margin-right: 16px;
    }
  }
}
</style>
