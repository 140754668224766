import { oneApi } from 'config/api';
import bus from 'common/bus.js';
import coursesSa from 'mixins/courses/sa.js';

export default {
  data() {
    return {
      classData: {},
      classFollow: this.$t('coursesDetail.classFollow'),
      id: '',
      followStatus: 0,
      followDialog: false,
      followDialogStatus: 1,
      isAppJsInject: false,
    }
  },
  mixins: [coursesSa],
  computed: {
    fromSourcePage() {
      return this.getClassFrom(this.$route.name)
    },
    detail() {
      return this.detailData || this.classData
    },
    isLogin() {
      return this.$store.state.ucenter.isLogin;
    },
    showStatus() {
      return !this.isLogin || (this.followStatus !== 0 && this.followStatus !== 3)
    },
    followDialogText() {
      if (this.followDialogStatus === 1) {
        return this.$t('coursesDetail.classFollow')
      }
      return this.$t('coursesDetail.classFollowCancel')
    },
  },
  watch: {
    isLogin(val) {
      if (val && this.id) {
        bus.$emit('courses.detail.refresh')
        bus.$emit('coursesDetail.refresh')
        this.getFollowRes(this.id)
      }
    },
  },
  mounted() {
    this.isAppJsInject = process.client ? !!window.JsInjectionActivity : false;
  },
  methods: {
    remindButtonShow(val) {
      if (val) {
        this.classData = val
      }
      const classData = this.getClassSaData(this.fromSourcePage, this.detail)
      const data = classData
      data.active_type = ((this.activityInfo && this.activityInfo.activityType === 6) || (this.classData && this.classData.activityItem && this.classData.activityItem.activityType === 6))
      data.button_name = this.lineUpStatus(val ? val.followStatus : '')
      this.Sensors.track('remind_button_show', data)
    },
    remindButtonClick() {
      const classData = this.getClassSaData(this.fromSourcePage, this.detail)
      const data = classData
      data.active_type = ((this.activityInfo && this.activityInfo.activityType === 6) || (this.classData && this.classData.activityItem && this.classData.activityItem.activityType === 6))
      data.button_name = this.lineUpStatus()
      this.Sensors.track('remind_button_click', data)
    },
    remindConfrimClick(val) {
      const classData = this.getClassSaData(this.fromSourcePage, this.detail)
      const data = {
        course_name: classData.course_name, course_id: classData.course_id, class_id: classData.class_id, previous_source: classData.previous_source,
      }
      data.button_name = val ? this.followDialogText.mainBtn : this.followDialogText.subBtn
      console.log(data)
      this.Sensors.track('remind_confrim_click', data)
    },
    remindCancelClick(val) {
      const classData = this.getClassSaData(this.fromSourcePage, this.detail)
      const data = {
        course_name: classData.course_name, course_id: classData.course_id, class_id: classData.class_id, previous_source: classData.previous_source,
      }
      data.button_name = val ? this.followDialogText.mainBtn : this.followDialogText.subBtn
      this.Sensors.track('remind_cancel_click', data)
    },
    lineUpStatus(val) {
      if (val === 1 || this.followStatus === 1 || !this.isLogin) {
        return this.$t('page.common.lineUp')
      }
      return this.$t('page.common.quitLineUp')
    },
    async getFollowRes(skuId) {
      this.id = skuId
      const data = {
        skuId,
        studentId: this.$route.query.studentId || '',
      }
      if (this.isLogin) {
        const res = await this.$axios.post(oneApi.followCheck, data, { rewritePostBody: true, uid: data.studentId });
        if (res.code !== 0) {
          this.$ElMessage.error(res.msg)
        }
        const resData = res.data || {}
        this.followStatus = resData.status
      }
      if (this.showStatus) {
        this.remindButtonShow()
      }
    },
    followHandler(status, id, data) {
      console.log('这1')
      if (status && !this.followStatus) {
        this.followStatus = status
      }
      if (id) {
        this.id = id
      }
      if (data) {
        this.classData = data
      }
      if (!this.isLogin) {
        if (process.env.clientType === 'web') {
          bus.$emit('login');
        } else {
          const currentFullPath = this.$route.fullPath
          const successUrl = encodeURI(currentFullPath)
          if (this.isAppJsInject) {
            this.$login(`/login?cancelUrl=${this.courseDetailPath}&triggerSrc=FOLLOW_COURSE&successUrl=${successUrl}&triggerSource=courses-detail-registration`, this.$router, '购买')
          } else {
            this.$router.replace(`/login?cancelUrl=${this.courseDetailPath}&successUrl=${successUrl}&triggerSrc=FOLLOW_COURSE&triggerSource=courses-detail-registration`);
          }
        }
        return
      }
      if (!this.$store.state.ucenter.user.email && !this.$store.state.ucenter.user.attentionEmail) {
        this.$store.dispatch('bindDialog/setDialogData', {
          visible: true,
          type: 'email',
          execute: true,
          guide: this.goEmail,
        })
        return
      }
      if (this.followStatus === 1) {
        this.lineUp()
      } else {
        this.followDialogStatus = 2
        this.followDialog = true
      }
      this.remindButtonClick()
    },
    async goEmail(emai) {
      // todo 跳过验证后逻辑
      console.log(`${emai}-----------------------`)
      const data = {
        type: 2,
        contactInfo: emai,
      }
      const res = await this.$axios.post(oneApi.contactVerify, data, { rewritePostBody: true })
      if (Number(res.code) !== 0) {
        if (process.env.clientType === 'web') {
          this.$ElMessage.error(res.msg)
        } else {
          this.$Toast(res.msg)
        }
        return;
      }
      this.$emit('submit', this.emailData)
      console.log('效验通过')
      const emailData = {
        attentionEmail: emai,
      }
      const emailRes = await this.$axios.post(oneApi.bindEmail2, emailData, { rewritePostBody: true, uid: this.$route.query.studentId || '' })
      // 触发后续购课流程
      if (Number(emailRes.code) !== 0) {
        if (process.env.clientType === 'web') {
          this.$ElMessage.error(res.msg)
        } else {
          this.$Toast(emailRes.msg)
        }
        return;
      }
      // this.$store.dispatch('bindDialog/close')
      this.$store.dispatch('bindDialog/setDialogData', {
        visible: false,
        type: 'email',
      })
      console.log('关弹窗')
      this.$store.dispatch('ucenter/queryUserBasicInfo', { $axios: this.$axios, userData: { fillInAttention: false } }).then(() => {
        this.followHandler()
      });
    },
    async lineUp(id) {
      const data = {
        skuId: id || this.id,
      }
      const res = await this.$axios.post(oneApi.followAdd, data, { rewritePostBody: true });
      if (res.code !== 0) {
        if (process.env.clientType === 'web') {
          this.$ElMessage.error(res.msg)
        } else {
          this.$Toast(res.msg)
        }
        return
      }
      this.followDialogStatus = 1
      this.followDialog = true
      this.followStatus = 2
    },
    async cancelLineUp(id) {
      const data = {
        skuId: id || this.id,
      }
      const res = await this.$axios.post(oneApi.followCancel, data, { rewritePostBody: true });
      if (res.code !== 0) {
        if (process.env.clientType === 'web') {
          this.$ElMessage.error(res.msg)
        } else {
          this.$Toast(res.msg)
        }
        return
      }
      if (process.env.clientType === 'web') {
        this.$ElMessage.success(this.$t('coursesDetail.classFollowCancel.successTip'))
      } else {
        this.$Toast(this.$t('coursesDetail.classFollowCancel.successTip'))
      }
      this.followDialogStatus = 2
      this.followDialog = false
      this.followStatus = 1
    },
    async confirmHandler() {
      console.log('进入取消')

      bus.$emit('courses.detail.refresh')
      bus.$emit('coursesDetail.refresh')
      if (this.followDialogStatus === 2) {
        // const data = {
        //   skuList: [this.$route.params.coursesId],
        // };
        // console.log('课程内取消收藏3')
        // await this.$axios.post(oneApi.queryCancelWish, data, { rewritePostBody: true });
        this.cancelLineUp()
        this.remindCancelClick(true)
        return
      }
      this.$router.push('/my-wish/list')
      this.remindConfrimClick(true)
    },
    handleCancel() {
      this.followDialog = false
      if (this.followDialogStatus === 1) {
        this.remindConfrimClick(false)
        bus.$emit('courses.detail.refresh')
        bus.$emit('coursesDetail.refresh')
      } else {
        this.remindCancelClick(false)
      }
    },
  },

}
