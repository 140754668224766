<template>
  <div class="wrapper">
    <template v-if="!buttonName">
      <ModuleTextHeader :title="title" :describe="describe" />
    </template>
    <template v-else>
      <ModuleMoreHeader
        :column-title="title" :more-name="buttonName" :more-url="buttonUrl"
        @more-click="buttonTrackReport({ buttonName })"
      />
      <div v-if="describe" class="text-overflow-ellipsis-4">
        {{ describe }}
      </div>
    </template>
    <div class="ad7-content">
      <div v-if="dataList.length === 1">
        <img v-lazy-load v-lazy-load :data-src="dataList[0].touchImageUrl" alt="">
      </div>
      <van-swipe v-else class="my-swipe" :autoplay="3000" indicator-color="#ff9f0a">
        <van-swipe-item v-for="(item, index) in dataList" :key="index">
          <img v-lazy-load v-lazy-load :data-src="item.touchImageUrl" alt="" loading="lazy" @click="cardTrackReport">
        </van-swipe-item>
      </van-swipe>
    </div>
  </div>
</template>

<script>
/**
 * 广告样式（七）
 */
import mixin from './mixin'
import trackReportMixin from '../Common/CommonMixin/trackReport'
import linkTargetMixin from '../Common/CommonMixin/linkTarget'
import ModuleMoreHeader from '../Common/ModuleMoreHeader/touch.vue'
import ModuleTextHeader from '../Common/ModuleTextHeader/touch.vue'

export default {
  name: 'Ad7',
  components: {
    ModuleMoreHeader,
    ModuleTextHeader,
  },
  mixins: [mixin, trackReportMixin, linkTargetMixin],
}
</script>
<style lang="scss" scoped>
.ad7-content {
  padding-top: 10px;

  img {
    width: 335px;
  }
}
</style>
