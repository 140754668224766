<template>
  <div v-if="bannerListLength > 0" class="banner-configruable-wrapper">
    <van-swipe :autoplay="10000" indicator-color="#FFAA0A" width="100vw" :show-indicators="false" @change="imageChangeHandler">
      <van-swipe-item v-for="(banner, index) in bannerList" :key="index" :style="{ height: getBannerHeight(banner.size) }">
        <!-- 样式一 不带按钮，图片可点击 -->
        <template v-if="getResourceType(banner) === 1">
          <a v-if="banner.url" class="banner-img-link" :href="banner.url" :target="banner.target?'_blank':''" @click="cardTrackReport">
            <img v-lazy="banner.src" class="banner-img" alt="">
          </a>
          <img v-else v-lazy="banner.src" class="banner-img" alt="">
        </template>
        <!-- 样式二 带按钮，图片不可点击 -->
        <template v-else>
          <div class="banner-img-wrapper">
            <img v-lazy="banner.src" class="banner-img" alt="">
            <div class="btns-group">
              <a v-for="(btn, btnIndex) in Array.prototype.slice.call(banner.widgets || {}, 0, 2)" :key="btnIndex"
                 class="button button-small button-banner"
                 :class="{'button-primary-white': btnIndex == 0, 'button-outline-white': btnIndex == 1, 'button-banner-default': btnIndex == 1}"
                 :href="btn.url" :target="btn.target?'_blank':''"
                 @click="buttonTrackReport({ buttonName: btn.title })"
              >
                {{ btn.title }}
              </a>
            </div>
          </div>
        </template>
      </van-swipe-item>
    </van-swipe>
    <div v-if="bannerListLength > 1" class="carousel-indicator-container">
      <ul class="carousel-indicator-list">
        <li v-for="index in bannerListLength" :key="index" class="carousel-indicator-item hover-transition" :class="{'actived': activedIndex+1 == index}" />
      </ul>
    </div>
  </div>
</template>

<script>
import mixin from './mixin';
import trackReportMixin from '../Common/CommonMixin/trackReport'

export default {
  mixins: [mixin, trackReportMixin],
  props: {
    // 轮播图高度
    bannerHeight: {
      default: '185.333334vw',
      type: String,
    },
  },
  methods: {
    /**
     * 获取banner高度
     * size: small/big
     */
    getBannerHeight(size) {
      return size === 'big' ? '185.33333vw' : '128vw'
    },
  },
}
</script>

<style lang="scss" scoped>
.banner-configruable-wrapper {
  position: relative;
  width: 100%;
  overflow: hidden;
  z-index: 1;

  .banner-img-link,
  .banner-img-wrapper {
    position: relative;
    display: block;
    width: 100%;
  }

  .banner-img {
    position: relative;
    display: block;
    width: 100%;
    background: var(--txt-color-link);
    font-size: 0;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .banner-img-wrapper {
    position: relative;

    .btns-group {
      position: absolute;
      bottom: 50px;
      display: flex;
      justify-content: space-between;
      padding: 0 20px;
      width: 100%;

      .button:not(:last-child) {
        margin-right: 15px;
      }

      .button.button-banner {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 17px 10px;
        height: 50px;
        font-size: 14px;
        line-height: 16px;
        overflow: hidden;

        a {
          color: inherit;
        }
      }
    }
  }

  .carousel-indicator-container {
    position: absolute;
    left: 50%;
    bottom: 20px;
    transform: translateX(-50%);
    z-index: 9;

    .carousel-indicator-list {
      display: flex;
      list-style: none;

      .carousel-indicator-item {
        margin: 0 5px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.4);
        cursor: pointer;

        &.actived {
          background: #fff;
        }
      }
    }
  }
}
</style>
