<template>
  <div class="get-notified" v-lazy-background lazy-background="./assets/bg-touch.png">
    <div class="title font-bold" v-html="title" />
    <div class="description font-medium" v-html="intro" />
    <div class="book-form">
      <div class="form-wrapper">
        <van-form
          ref="SubmitInfo"
          @submit="handleSubmit"
        >
          <van-field
            v-model="formData.firstName"
            :placeholder="firstName"
            :rules="rules.firstName"
            class="item-field"
          />
          <van-field
            v-model="formData.lastName"
            :placeholder="lastName"
            :rules="rules.lastName"
            class="item-field"
          />
          <van-field
            v-model="formData.email"
            :placeholder="emailName"
            :rules="rules.email"
            class="item-field"
          />
          <div class="field-grade">
            <van-field
              readonly
              clickable
              name="picker"
              :value="formData.gradeName"
              :rules="rules.grade"
              :placeholder="gradeName"
              class="item-field"
              @click="showGradePicker = true"
            />
            <img class="downarrow" v-lazy-load data-src="~assets/images/staticResources/downArrow.png" alt="">
          </div>
          <van-popup v-model="showGradePicker" position="bottom">
            <van-picker
              show-toolbar
              value-key="name"
              :columns="gradeList"
              @confirm="onConfirmGrade"
              @cancel="showGradePicker = false"
            />
          </van-popup>
          <van-field
            class="item-field"
          >
            <template #input>
              <van-checkbox v-model="formData.subStatus" class="subStatus-checkbox white-grace-checkbox">
                <template #icon="props">
                  <div>
                    <img v-if="props.checked" class="custom-checkbox-icon" v-lazy-load data-src="./assets/icon-checkbox-checked.png" alt="">
                    <img v-else class="custom-checkbox-icon" v-lazy-load data-src="./assets/icon-checkbox-normal.png" alt="">
                  </div>
                </template>

                <div class="checkbox-content font-medium">
                  {{ agreementNotice }}
                </div>
              </van-checkbox>
            </template>
          </van-field>
        </van-form>
        <div class="button-wrapper">
          <van-button round type="primary" class="button btn-submit" :text="submitName" :loading="loading" @click="onSubmit" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import mixin from './mixin.js';
import trackReportMixin from '../Common/CommonMixin/trackReport'
import { oneApi } from 'config/api';
import dataReport from 'common/dataReport'

export default {
  mixins: [mixin, trackReportMixin],
  data() {
    return {
      // 是否显示年级选择控件
      showGradePicker: false,
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    // 提交按钮事件
    onSubmit() {
      this.$refs.SubmitInfo.submit();
    },

    // 处理提交按钮操作
    handleSubmit() {
      this.save(() => {
        this.$Notify({ type: 'success', message: this.successNotice })
      });
    },

    // 数据保存
    async save(callback) {
      const data = {
        firstName: this.formData.firstName,
        lastName: this.formData.lastName,
        email: this.formData.email,
        gradeId: this.formData.gradeId,
        subStatus: this.formData.subStatus ? 1 : 0,
        channelId: 1,
        sourceCode: 'touch',
        mediaCode: 'touch_lead',
      }

      // 打开Loading
      this.loading = true;
      // 保存数据接口
      const res = await this.$axios.post(oneApi.addSaleslead, data, { rewritePostBody: true });
      // 关闭Loading
      this.loading = false;

      // 业务异常阻断
      if (Number(res.code) !== 0) {
        return this.$Notify({
          message: res.msg,
          type: 'warning',
        });
      }

      // 埋点上报
      this.buttonTrackReport({
        buttonName: this.submitName,
      })
      if (this.$store.state.locale === 'us' || this.$store.state.locale === 'hk') {
        window.dataLayer.push({
          'event': 'generatelead_gtm',
        });
      }
      // 留资组件提交第三方埋点上报
      dataReport.launchReport({
        gtagOptions: {
          name: 'GenerateLead',
          params: {
            event_category: 'GenerateLead',
          },
        },
        fbqOptions: {
          name: 'Lead',
        },
        ttqOptions: {
          name: 'SubmitForm',
        },
      })

      // 执行回调
      if (callback) { callback() }
      // 清空表单
      this.resetData();
      return res
    },

    // 重置数据
    resetData() {
      this.formData.firstName = ''
      this.formData.lastName = ''
      this.formData.email = ''
      this.formData.gradeId = ''
      this.formData.gradeName = ''
    },

    /**
     * 选择年级事件
     */
    onConfirmGrade(grade) {
      this.formData.gradeId = grade.value
      this.formData.gradeName = grade.name
      this.showGradePicker = false;
    },
  },
}
</script>
<style lang="scss" scoped>
.get-notified {
  position: relative;
  padding: px2vw(60) px2vw(40) px2vw(40) px2vw(40);
  z-index: 3;
  background-color: #feb440;
  background-repeat: no-repeat;
  background-size: 100%;

  .title {
    font-size: px2vw(48);
    color: #fff;
    line-height: px2vw(56);
    margin-bottom: px2vw(20);
  }

  .description {
    font-size: px2vw(28);
    color: #fff;
    line-height: px2vw(36);
    margin-bottom: px2vw(40);
  }

  .book-form {
    background: #fff;
    border-radius: px2vw(20);
    overflow: hidden;
    margin-top: px2vw(48);
    margin-bottom: px2vw(48);
    padding: px2vw(60)  px2vw(40);

    .item-field,
    .phone-input {
      border: none;
      background: rgba(242, 242, 242, 1);
      padding: px2vw(26) px2vw(48) px2vw(26) px2vw(40);
      border-radius: px2vw(60);
      margin-bottom: px2vw(30);
      font-weight: 500;
      overflow: visible;

      /deep/.van-field__control {
        padding-right: px2vw(40);
        font-weight: 500;

        &::placeholder {
          font-size: px2vw(32);
          font-weight: 500;
          color: var(--txt-color-lv2);
          line-height: 16px;
        }
      }

      &.van-field--error {
        /deep/.van-field__control,
        /deep/.van-field__control::placeholder {
          color: var(--txt-color-lv2) !important;
        }
      }

      &.platform {
        /deep/ {
          .van-field__control:read-only {
            overflow: hidden;
            text-overflow: ellipsis;
            padding-right: px2vw(40);
          }
        }
      }

      &:nth-last-child(1) {
        background: none;
        margin-bottom: 0;
        padding: 0;
      }

      /deep/ .van-field__error-message {
        position: absolute;
        top: px2vw(60);
      }

      &::after {
        border-bottom: none;
      }

      /deep/.van-field__left-icon .phone-country-code-container {
        width: px2vw(108);
        display: flex;
        justify-content: space-between;
      }
    }

    .subscribe-status-checkbox {
      align-items: flex-start;

      .van-checkbox__icon {
        margin-top: px2vw(8);
      }

      /deep/.van-checkbox__label {
        color: var(--txt-color-lv2);
        font-size: px2vw(20);
        line-height: px2vw(32);
      }
    }

    .field-grade,
    .field-platform {
      position: relative;

      .downarrow {
        position: absolute;
        width: px2vw(32);
        height: px2vw(32);
        right: px2vw(40);
        top: px2vw(32);
      }

      .iconfont {
        position: absolute;
        top: px2vw(48);
        right: px2vw(48);
        color: #999;
      }
    }
  }

  .subStatus-checkbox {
    .custom-checkbox-icon {
      display: flex;
      margin-top: px2vw(2);
      width: px2vw(24);
      height: px2vw(24);
      overflow: hidden;
    }

    .checkbox-content {
      font-size: px2vw(24);
      line-height: px2vw(28);
      color: var(--txt-color-lv2);

      .link-text {
        text-decoration: underline;
        color: #3370ff;
      }
    }
  }

  .button-wrapper {
    margin-top: px2vw(40);
    text-align: center;

    .btn-submit {
      font-weight: bold;
      line-height: px2vw(96);
      width: px2vw(320);
      height: px2vw(96);
      background: var(--txt-color-link);
      border-radius: px2vw(64);

      /deep/ .van-button__text {
        font-size: px2vw(32);
      }
    }
  }

  .van-icon {
    position: absolute;
    top: px2vw(36);
    right: px2vw(40);
  }
}
</style>
