<template>
  <div class="wrapper">
    <ModuleTextHeader :title="title" :describe="describe" />
    <div class="ad1-content">
      <DiffLinkLabel
        v-for="(item, index) in dataList" :key="index"
        :url="item.linkUrl" :target="linkTarget"
      >
        <div class="ad1-wraper" @click="cardTrackReport({ cardName: item.title })">
          <img v-lazy-load v-lazy-load :data-src="item.imageUrl" alt="">
          <div class="text-overflow-ellipsis item-title">
            {{ item.title }}
          </div>
        </div>
      </DiffLinkLabel>
    </div>
    <div v-if="buttonName" class="button btn-wraper" @click="buttonTrackReport({ buttonName })">
      <DiffLinkLabel :url="buttonUrl" :target="linkTarget">
        <div class="ad2-btn text-overflow-ellipsis">
          {{ buttonName }}
        </div>
      </DiffLinkLabel>
    </div>
  </div>
</template>

<script>
/**
 * 广告样式（一）
 */
import mixin from './mixin'
import trackReportMixin from '../Common/CommonMixin/trackReport'
import linkTargetMixin from '../Common/CommonMixin/linkTarget'
import ModuleTextHeader from '../Common/ModuleTextHeader/touch.vue'
import DiffLinkLabel from '../Common/DiffLinkLabel/touch.vue'

export default {
  name: 'Ad1',
  components: {
    ModuleTextHeader,
    DiffLinkLabel,
  },
  mixins: [mixin, trackReportMixin, linkTargetMixin],
}
</script>
<style lang="scss" scoped>
.ad1-content {
  display: flex;
  flex-wrap: wrap;

  .ad1-wraper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 5px 20px 30px;
    font-size: 14px;
    font-weight: bold;

    img {
      width: 74px;
      height: 60px;
      margin-bottom: 10px;
    }

    .item-title {
      width: 120px;
      text-align: center;
    }
  }
}

.btn-wraper {
  display: block;
  width: 285px;
  margin: 20px auto 0;
  padding: 10px 20px;
  font-size: 16px;
  line-height: 30px;
  font-weight: bold;
  background: var(--txt-color-link);
  border-radius: 32px;

  .ad2-btn {
    color: #fff;
  }
}
</style>
