<template>
  <div class="mock-exam-card">
    <div class="card-body">
      <div class="title">
        {{ title }}
      </div>
      <div class="field-group">
        <div class="field-name">
          {{ langContext.difficulty }}:
        </div>
        <div class="field-content">
          <Rate :rate-level="difficulty" :max="5" icon="icon-star" />
        </div>
      </div>
      <div class="field-group">
        <div class="field-name">
          {{ langContext.mockDuration }}:
        </div>
        <div class="field-content color-gray">
          {{ examMockDuration }} mins
        </div>
      </div>
      <div class="field-group vertical">
        <div class="field-name">
          {{ langContext.intro }}:
        </div>
        <div class="field-content color-gray text-overflow-ellipsis-2">
          {{ intro }}
        </div>
      </div>
    </div>
    <div class="card-foot">
      <div class="card-attr">
        <div class="attr-item attr-time">
          {{ moment(datetime).format(datetimeFormat) }}
        </div>
        <div class="attr-item attr-people">
          {{ examPersonNums }}
        </div>
      </div>
      <div class="card-action" :class="{ 'button-num-2': buttons.length === 2 }">
        <template v-for="(item, index) in buttons">
          <div
            v-if="item.type === 'exam'"
            :key="index"
            class="button-item"
            @click="buttonTrackReport({ buttonName: item.name })"
          >
            <van-button round block type="primary" class="button button-start-now" :text="item.name" @click="handleExamButton(item.id)" />
          </div>
          <div
            v-if="item.type === 'download'"
            :key="index"
            class="button-item"
            @click="handleDownloadButton(item.id); buttonTrackReport({ buttonName: item.name })"
          >
            <van-button round block type="primary" class="button button-outline button-download" :text="item.name" />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 模拟考试卡片组件
 */
import { oneApi } from 'config/api'
import { resourceUrl } from 'common/utils.js'
import mixin from './mixin'
import trackReportMixin from '../Common/CommonMixin/trackReport'
import Rate from 'components/common/Rate'

export default {
  name: 'MockExamCard',
  components: {
    Rate,
  },
  mixins: [mixin, trackReportMixin],
  data() {
    return {
      resourceUrl: resourceUrl(),
      typeMap: {
        1: 'pdf',
        2: 'word',
        3: 'excel',
        4: 'mp4',
      },
    }
  },
  methods: {
    /**
     * 处理诊断按钮逻辑
     * @param {Number} id 诊断id
     */
    handleExamButton(id) {
      if (!this.isLogin) {
        this.login()
        return
      }
      this.examJump(id)
    },

    /**
     * 处理资源下载跳转
     * @param {Number} id 资源id
     */
    async handleDownloadButton(id) {
      console.log('PC邮箱1')
      if (!this.isLogin) {
        this.login()
        return
      }
      const info = await this.$axios.post(oneApi.queryUserBasicInfo, { fillInAttention: true }, { rewritePostBody: true })
      if (!info.data.email) {
        this.$store.dispatch('bindDialog/setDialogData', {
          visible: true,
          type: 'email',
        })
        return
      }
      if (!info.data.phone) {
        this.$store.dispatch('bindDialog/setDialogData', {
          visible: true,
          type: 'phone',
        })
        return
      }
      const res = await this.$axios.post(oneApi.getUrl, id, { rewritePostBody: true });
      if (Number(res.code) !== 0) {
        this.$ElMessage.error(res.msg);
        return
      }
      const resData = res.data || {}
      this.$router.push({ name: 'resources-view-id', params: { id }, query: { resource: this.resourceUrl + resData.url, type: this.typeMap[this.downloadFormatType], name: resData.fileName } })
    },

    /**
     * 登录
     */
    login() {
      const currentFullPath = this.$route.fullPath
      const backUrl = encodeURI(currentFullPath)
      this.$router.replace(`/login?&cancelUrl=${backUrl}&successUrl=${backUrl}`);
    },
  },
}
</script>

<style lang="scss" scoped>
.mock-exam-card {
  padding: 20px;
  background: #fff;
  border-radius: 16px;
  border: 1px solid #f1f1f1;

  .card-attr {
    position: relative;
    display: flex;
    margin: 10px 0 16px 0;
    padding-top: 10px;

    .attr-item {
      margin-right: 40px;
      font-size: 16px;
      color: var(--txt-color-lv1);
      padding-left: 25px;

      &.attr-time {
        background: url('./assets/icon-clock.png') left center no-repeat;
        background-size: 16px 16px;
      }

      &.attr-people {
        background: url('./assets/icon-people.png') left center no-repeat;
        background-size: 16px 16px;
      }
    }

    &::after {
      content: " ";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      padding-top: 10px;
      height: 1px;
      border-top: 1px dashed #ebebeb;
    }
  }

  .card-main {
    width: 740px;
  }

  .card-action {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;

    .button-item {
      flex: 1;
    }

    .button {
      height: 40px;
      font-weight: 600;
    }

    .button-start-now {
      background: var(--txt-color-link);
    }

    &.button-num-2 {
      .button-item:first-child {
        margin-right: 10px;
      }

      .button-item:last-child {
        margin-left: 10px;
      }
    }
  }

  .title {
    margin-bottom: 8px;
    font-size: 16px;
    color: var(--txt-color-lv1);
    font-weight: 500;
    word-break: break-word;
  }

  .field-group {
    margin-bottom: 8px;
    display: flex;

    .field-name {
      margin-right: 15px;
      color: var(--txt-color-lv1);
    }

    .field-content {
      font-size: 16px;
      line-height: 20px;
      word-break: break-word;
    }

    &.vertical {
      display: block;

      .field-name {
        margin-bottom: 4px;
      }
    }
  }

  .color-gray {
    color: rgba(23, 43, 77, 0.6);
  }
}
</style>
