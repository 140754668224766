<template>
  <div class="image-list-wrapper">
    <div class="title">
      <p v-if="columnTheme" class="title_columnTheme text-overflow-ellipsis-2">
        {{ columnTheme }}
      </p>
      <p v-if="ColumnIntroduction" class="content_ColumnIntroduction text-overflow-ellipsis-3">
        {{ ColumnIntroduction }}
      </p>
    </div>
    <ul v-if="hasTouch" class="image-list">
      <li v-for="(item, index) in dataList" :key="'web-'+index" class="image-item">
        <img v-lazy-load :data-src="item.touchImage" :alt="item.hasTouchAlt" @click="cardTrackReport(item)">
      </li>
    </ul>
  </div>
</template>

<script>
  /**
     * 通用图片组件2
     */
  import mixin from './mixin'
  import trackReportMixin from '../Common/CommonMixin/trackReport'

  export default {
    name: 'GeneralImgTwo',
    mixins: [mixin, trackReportMixin],
    data() {
      return {
      }
    },
    methods: {
      cardTrackReport(item) {
        // 埋点上报
        this.buttonTrackReport({
          buttonName: 'GeneralImgTwo',
        });
        console.log(item)
        if (item.linkUrl) {
          if (item.target === '_blank') { // 在当前页面打开
            window.location.href = item.linkUrl;
          }
          if (item.target === '_self') { // 在新标签页打开
            window.open(item.linkUrl, '_blank');
          }
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  @import './common.scss';

  .image-list-wrapper .image-list {
    width: 100%;
    margin: auto;
    border-radius: 16px;
  }

  .title {
    width: 100%;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 16px;
    padding-right: 16px;

    .title_columnTheme {
      font-weight: bold;
      color: #222222;
      font-size: 24px;
      line-height: 30px;
      font-family: Montserrat-Bold, Montserrat;
      margin-bottom: 20px;
      text-align: center;
    }

    .content_ColumnIntroduction {
      color: #222;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      margin-bottom: 20px;
    }
  }
</style>