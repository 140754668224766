<template>
  <div class="jump-chat">
    <i class="iconfont icon-robot" @click="handleInitDebounce" />
    <ChatRobot ref="chatRobot" @closeChat="handleCloseChat" />
  </div>
</template>

<script>
import { oneApi } from 'config/api';
import ChatRobot from '@/components/global/FeatureComponents/ChatRobot/ChatRobotComp/touch.vue'

const debounce = require('lodash/debounce')

export default {
  components: {
    ChatRobot,
  },
  data() {
    return {
      showChatRobot: false,
    }
  },
  methods: {
    // 改变筛选项 防抖
    handleInitDebounce: debounce(function () {
      this.init()
    }, 300),
    async init() {
      // 正在交互或非首次打开
      if (this.$refs.chatRobot?.isInteracting || this.$refs.chatRobot?.webchatSid) {
        this.showChatRobot = true
        this.$refs.chatRobot.openChat()
        return
      }
      // 首次打开
      const res = await this.$axios.post(oneApi.startChatSession, {}, { rewritePostBody: true })
      if (res.code !== 0) {
        this.$Toast('该功能暂未开通，敬请期待');
        return
      }
      const resData = res.data || {}
      this.showChatRobot = true
      this.$refs.chatRobot.openChat(resData)
    },
    // 关闭聊天机器人
    handleCloseChat() {
      this.$nextTick(() => {
        this.showChatRobot = false
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.jump-chat {
  position: fixed;
  z-index: 1001;
  bottom: 80px;
  right: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10.66667vw;
  height: 10.66667vw;
  font-size: 20px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 3px 10px 0 rgba(23, 43, 77, 0.1);

  .icon-robot {
    font-size: 20px;
  }
}
</style>
