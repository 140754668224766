<template>
  <div id="chat-input" ref="chatInput" class="chat-input" :class="{'hide': isHide, 'has-input': hasInput}">
    <div v-if="maxTextLen" class="chat-input-tip">
      <img v-lazy-load data-src="../imgs/warning.png" alt="">
      <span class="max-text">* Maximum of 500 characters is allowed.</span>
    </div>
    <van-field
      ref="input"
      v-model="textarea"
      rows="1"
      type="textarea"
      contenteditable="true"
      :autosize="{ maxHeight: 176 }"
      :maxlength="maxLen"
      placeholder="Write a reply…"
      @keyup.enter.native="handleKeyup"
      @keydown.enter.native="handleKeydown"
    />
    <i class="iconfont icon-send" @click="handlemessageSend" />
  </div>
</template>

<script>
import mixin from './mixin.js';

export default {
  mixins: [mixin],
  watch: {
    textarea() {
      this.handleHeightChange()
    },
    isHide(newVal) {
      if (!newVal) {
        this.$nextTick(() => {
          this.$refs.input.focus();
        });
      }
    },
  },
  methods: {
    handleHeightChange() {
      const offsetHeight = this.textarea === '' ? 56 : this.$refs.chatInput?.offsetHeight
      this.$emit('isinput', offsetHeight)
    },
  },
}
</script>

<style lang="scss" scoped>

.chat-input {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  // display: flex;
  // flex: 1;
  // align-items: center;
  // justify-content: space-between;
  border-top: 1px solid #e6e6e6;
  background: #fff;

  .send-img {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  /deep/.van-field {
    padding: 17px 0 17px 20px;
    line-height: 22px;

    &::after {
      border-bottom: none;
    }

    textarea {
      min-height: 22px !important;
      max-height: 166px;
      padding-right: 88px;
      font-size: 14px;
      font-weight: 500;
      color: var(--txt-color-lv1);
      line-height: 22px;
    }
  }

  .chat-input-tip {
    position: absolute;
    top: -38px;
    left: 20px;
    display: flex;
    align-items: center;
    padding: 9px 10px;
    border-radius: 4px;
    background: rgba(255, 80, 63, 0.1);
    border: 1px solid rgba(255, 80, 63, 0.6);

    img {
      width: 14px;
      height: 14px;
      object-fit: contain;
    }

    .max-text {
      font-size: 12px;
      line-height: 14px;
      color: #ff503f;
    }
  }

  .icon-send {
    position: absolute;
    right: 20px;
    top: 17px;
    font-size: 24px;
    cursor: pointer;
  }
}

.hide {
  visibility: hidden;
  opacity: 0;
}

.has-input {
  .icon-send {
    color: var(--txt-color-link);
  }
}
</style>
