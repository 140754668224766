<template>
  <div class="faq-card">
    <h4 class="faq-title">
      {{ title }}
    </h4>
    <ul class="fap-list">
      <li
        v-for="(faq, index) in dataList"
        :key="`faq-${index}`"
        v-sa-track="[{type: 'visually', event: 'component_card_show', data: cardshowTrackReport({ cardName: faq.title })}]"
        class="faq-item"
        @click="changeAnswerStatus(index); cardTrackReport({ cardName: faq.title })"
      >
        <div class="question-container" :class="{'high-light': answerHeight[index] }">
          <span class="que-seq">{{ `${index + 1}.` }}</span>
          <span class="que-title">{{ faq.title }}</span>
          <div class="que-icon" />
        </div>
        <div class="answer-container" :style="{'height': answerHeight[index] || 0}">
          <div :ref="'fagAnswer' + index" class="answer" v-html="faq.description.replace(/[\r\n]/g, '<br />')" />
          <img v-if="faq.touchImage" :ref="'fagPic' + index" class="pic" :data-not-lazy="true" v-lazy-load :data-src="faq.touchImage" alt="touchImage" :onload="imgLoaded(index)">
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import mixin from './mixin'
import trackReportMixin from '../Common/CommonMixin/trackReport'

export default {
  mixins: [mixin, trackReportMixin],
}
</script>

<style lang="scss" scoped>
.faq-card {
  padding: 20px 16px;
  background: rgba(245, 245, 245, 1);
}

.faq-title {
  margin-bottom: 20px;
  font-size: 22px;
  font-weight: bold;
  line-height: 30px;
  text-align: center;
}

ul.fap-list {
  list-style: none;
}

li.faq-item {
  margin-bottom: 10px;
  padding: 16px;
  border-radius: 8px;
  background: #fff;
  border: 2px solid rgba(34, 34, 34, 0.05);

  .question-container {
    position: relative;
    display: flex;
    padding-right: 34px;
    font-size: 14px;
    font-weight: bold;
    line-height: 22px;
    color: var(--txt-color-lv2);

    .que-seq {
      width: 18px;
      min-width: 18px;
    }
  }

  .que-icon {
    width: 24px;
    height: 24px;
    position: absolute;
    right: 0;
    top: 0;
    transition: all 0.4s;
    background: url('./assets/imgs/icon_more.png') 0/100% no-repeat;
  }

  .high-light {
    color: var(--txt-color-lv1);

    .que-icon {
      transform: rotate(-180deg);
    }
  }

  .answer-container {
    height: 0;
    overflow: hidden;
    transition: height 0.4s;

    .answer {
      padding: 16px 18px 0;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: var(--txt-color-lv1);
    }

    .pic {
      width: calc(100% - 36px);
      border-radius: 8px;
      margin: 16px 18px 0;
      border: 1px solid whitesmoke;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}
</style>
