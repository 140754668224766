<template>
  <div class="courses-card-wrapper">
    <nuxt-link :to="linkUrl">
      <div class="card-body">
        <div class="courses-title text-overflow-ellipsis-2">
          {{ courseDetail.showName }}
        </div>
      </div>
      <CoursesColumnFooter :course-detail="courseDetail" />
    </nuxt-link>
  </div>
</template>

<script>
import mixin from './mixin'
import CoursesColumnFooter from '../../Common/CoursesColumnFooter/touch'

export default {
  components: {
    CoursesColumnFooter,
  },
  mixins: [mixin],
}
</script>

<style lang="scss" scoped>
.courses-card-wrapper {
  .card-body {
    height: 96px;
    background: var(--txt-color-link) url('../assets/imgs/card-bg.png') no-repeat;
    background-size: 100%;
    border-radius: 10px 10px 0 0;
    margin: -1px -1px 0 -1px;
    overflow: hidden;

    .courses-title {
      height: 52px;
      line-height: 26px;
      margin: 22px 12px;
      font-size: 18px;
      color: #fff;
      font-weight: bold;
    }
  }
}
</style>
