<template>
  <div class="mock-exam-column">
    <div class="column-wrapper">
      <div class="column-head">
        <div class="column-title">
          {{ columnTitle }}
        </div>
        <div class="column-intro">
          {{ columnIntro }}
        </div>
      </div>
      <div class="column-body">
        <div v-for="(item, index) in resources" :key="index" class="card-wrapper">
          <MockExamCard
            :key="index"
            v-sa-track="[{type: 'visually', event: 'component_card_show', data: cardshowTrackReport({ cardName: item.title })}]"
            :datetime="datetime"
            :datetime-format="datetimeFormat"
            :component-name="componentName"
            :component-type="componentType"
            :component-title="componentTitle"
            v-bind="item"
          />
        </div>
      </div>
      <!-- <div class="column-more">
        <div class="button-more">
          More <i class="iconfont icon-arrow-right-bold" />
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
/**
 * 模拟考试栏目touch组件
 */
import mixin from './mixin'
import trackReportMixin from '../Common/CommonMixin/trackReport'
import MockExamCard from '../MockExamCard/touch'

export default {
  name: 'MockExamColumn',
  components: {
    MockExamCard,
  },
  mixins: [mixin, trackReportMixin],
}
</script>

<style lang="scss" scoped>
.mock-exam-column {
  position: relative;
  margin: 0 16px;
  background: #fff0d7 url('./assets/icon-thinkacademy.png') 230px -18px no-repeat;
  background-size: 243px 330px;
  border-radius: 10px;

  .column-wrapper {
    padding: 20px;
  }

  .card-wrapper {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .column-head {
    margin-bottom: 20px;
  }

  .column-title {
    margin-bottom: 10px;
    color: var(--txt-color-link);
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    word-break: break-all;
  }

  .column-intro {
    font-size: 14px;
    color: var(--txt-color-link);
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    word-break: break-all;
  }

  .column-more {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    background: #fff;
    border-radius: 24px;
    cursor: pointer;

    .button-more {
      color: var(--txt-color-link);
      font-size: 14px;
      font-weight: 500;

      .icon-arrow-right-bold {
        font-size: 12px;
      }
    }
  }
}
</style>
