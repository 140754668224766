<template>
  <div class="chat-window" :class="isChatOpen ? 'opend' : 'closed'">
    <!-- <van-popup v-model="isChatOpen" :lazy-render="false" :overlay="false" style="width: 100%; height: 100%;"> -->
    <Header @close="handleCloseChat" />
    <MessageList ref="messageList" :messages="messageList" :bottom-height="inputHeight" @retry="hanfleRetrySend" />
    <UserInput ref="userBottom" :is-hide="isInteracting" @send="handleSend" @isinput="handleIsInput" />
    <!-- </van-popup> -->
  </div>
</template>

<script>
import mixin from './mixin.js';
import Header from './Header/touch.vue'
import MessageList from './MessageList/touch.vue'
import UserInput from './UserInput/touch.vue'

export default {
  components: {
    Header,
    MessageList,
    UserInput,
  },
  mixins: [mixin],
  data() {
    return {
      scrollTop: 0, // 页面滑动高度
      inputHeight: 56, // 底部输入框高度
    }
  },
  watch: {
    isInteracting() {
      this.inputHeight = 56
    },
  },
  beforeDestroy() {
    this.handleCloseChat()
  },
  methods: {
    handleCloseChat() {
      this.startDefault()
      this.isChatOpen = false
      this.$emit('closeChat')
    },
    handleIsInput(value) {
      this.inputHeight = value
    },
    stopDefault() {
      this.scrollTop = document.body.scrollTop || document.documentElement.scrollTop
      document.body.style.top = `-${this.scrollTop}px`
      document.body.classList.add('over-body')
    },
    startDefault() {
      document.body.scrollTop = this.scrollTop
      document.documentElement.scrollTop = this.scrollTop
      document.body.classList.remove('over-body')
    },
  },
  head() {
    // 限制弹窗缩放
    const content = this.$store.state.locale === 'us' && this.isChatOpen
      ? 'width=device-width, initial-scale=1.0, maximum-scale=1.0'
      : 'width=device-width, initial-scale=1.0'
    return {
      meta: [{
        name: 'viewport',
        content,
      }],
    }
  },
}
</script>

<style lang="scss" scoped>
.chat-window {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  // height: 100vh;
  background: #fdfdfd;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;
  animation: fadeIn;
  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
}

.closed {
  opacity: 0;
  display: none;
}

@keyframes fadeIn {
  0% {
    display: none;
    opacity: 0;
  }

  100% {
    display: flex;
    opacity: 1;
  }
}

.chat-window.closed {
  opacity: 0;
  display: none;
}

/deep/.van-popup--center {
  left: 0;
  right: 0;
  top: 0;
  transform: none;
}
</style>
