<template>
  <div class="class-column-wrapper">
    <ModuleTextHeader :title="title" :describe="description" />
    <div class="class-card-wrappoer">
      <div v-for="(classItem, index) in dataList" :key="index" class="class-card-item-wrapper" aria-label="Class Card" @click="cardTrackReport({ cardName: classItem.title })">
        <ClassCard
          v-sa-track="[{type: 'visually', event: 'component_card_show', data: cardshowTrackReport({ cardName: classItem.title })}]"
          class="class-card-in-list"
          :card-data="classItem"
          :show-wrapper-border="true"
          :show-original-price="true"
          :show-taxation-desc="true"
          :group-buy="classItem.activityItem"
          :show-price-is-include="classItem.showPriceIsInclude===1"
          :show-card-bottom-left="true"
          :total-lessons="classItem.spec.lessonCount"
          :left-lessons="classItem.spec.leftLesson"
          :show-right-top-tag="!classItem.spec.courseClosed"
          :show-back-stamp="true"
          :tag-type="getClassHotTagType(classItem.store, classItem.sellStore, classItem.remainSellTime)"
          :show-per-lesson="classItem.perShowPrice !== undefined"
        />
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 通用图片组件
 */
import mixin from './mixin'
import trackReportMixin from '../Common/CommonMixin/trackReport'
import ModuleTextHeader from '../Common/ModuleTextHeader/web'
import ClassCard from 'components/courses/ClassCard'

export default {
  name: 'ClassColumn',
  components: {
    ModuleTextHeader,
    ClassCard,
  },
  mixins: [mixin, trackReportMixin],
}
</script>

<style lang="scss" scoped>
.class-column-wrapper {
  padding: 20px 16px;
}

.class-card-item-wrapper {
  margin-bottom: 16px;
}
</style>
