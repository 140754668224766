<template>
  <div>
    <div class="header">
      <p v-if="title" class="title">
        {{ title }}
      </p>
      <p v-if="description" class="intro">
        {{ description }}
      </p>
    </div>
    <div v-if="bannerListLength > 0" class="banner-configruable-wrapper">
      <van-swipe :autoplay="10000" indicator-color="#FFAA0A" width="100vw" :show-indicators="false" @change="imageChangeHandler">
        <van-swipe-item v-for="(item, index) in dataList" :key="index" style="height: 128vw;">
          <a v-if="item.linkUrl && item.linkUrl !== 'null'" class="banner-img-link" :href="item.linkUrl" :target="item.target?'_blank':''" @click="cardTrackReport">
            <img :data-not-lazy="true" v-lazy-load :data-src="item.touchImage" style="height: 128vw;" class="banner-img" :alt="item?.touchAltInfo || ''">
          </a>
          <div v-else-if="item.linkUrl === 'null'">
            <img :data-not-lazy="true" v-lazy-load :data-src="item.touchImage" style="height: 128vw;" class="banner-img" :alt="item?.touchAltInfo || ''">
          </div>
          <div v-else class="banner-img-link" @click="handleEvaluation">
            <img :data-not-lazy="true" v-lazy-load :data-src="item.touchImage" style="height: 128vw;" class="banner-img" :alt="item?.touchAltInfo || ''">
          </div>
        </van-swipe-item>
      </van-swipe>
      <div v-if="bannerListLength > 1" class="carousel-indicator-container">
        <ul class="carousel-indicator-list">
          <li v-for="index in bannerListLength" :key="index" class="carousel-indicator-item hover-transition" :class="{'actived': activedIndex+1 == index}" />
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from './mixin';
import trackReportMixin from '../Common/CommonMixin/trackReport'

export default {
  mixins: [mixin, trackReportMixin],
  methods: {
    handleEvaluation() {
      // 埋点上报
      this.buttonTrackReport({
        buttonName: 'toShowDiagnosis',
      });
      if (!this.isLogin) {
        const currentFullPath = this.$route.fullPath
        const backUrl = encodeURI(currentFullPath)
        this.$router.replace(`/login?cancelUrl=${backUrl}&successUrl=${backUrl}&triggerSource=appointment`)
        return
      }
      const currentFullPath = this.$route.fullPath
      const backUrl = encodeURI(currentFullPath)
      this.$router.replace(`/appointmentDiagnosis?successUrl=${backUrl}`);
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  text-align: center;
  padding: 16px;

  .title {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    font-size: 24px;
    font-weight: bold;
    color: #222;
    line-height: 30px;
    margin-bottom: 20px;
  }
  .intro {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    font-size: 14px;
    font-weight: 400;
    color: #222;
    line-height: 20px;
  }
}

.banner-configruable-wrapper {
  position: relative;
  width: 100%;
  overflow: hidden;
  z-index: 1;

  .banner-img-link {
    position: relative;
    display: block;
    width: 100%;
  }

  .banner-img {
    position: relative;
    display: block;
    width: 100%;
    background: var(--txt-color-link);
    font-size: 0;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .carousel-indicator-container {
    position: absolute;
    left: 50%;
    bottom: 20px;
    transform: translateX(-50%);
    z-index: 9;

    .carousel-indicator-list {
      display: flex;
      list-style: none;

      .carousel-indicator-item {
        margin: 0 6px;
        width: 20px;
        height: 4px;
        border-radius: 2px;
        background: #ededed;
        cursor: pointer;

        &.actived {
          background: #ffa827;
        }
      }
    }
  }
}
</style>
