<template>
  <div class="wrapper">
    <ModuleTextHeader :title="title" :describe="describe" />
    <div class="ad5-content">
      <DiffLinkLabel
        v-for="(item, index) in dataList" :key="index"
        :url="item.linkUrl"
        :target="linkTarget"
      >
        <div
          :style="getBackgroundStyle()"
          v-lazy-background :lazy-background="item.imageUrl"
          class="ad5-wraper"
          @click="cardTrackReport({ cardName: item.title })"
        >
          <div class="title text-overflow-ellipsis-2">
            {{ item.title }}
          </div>
        </div>
      </DiffLinkLabel>
    </div>
  </div>
</template>

<script>
/**
 * 广告样式（五）
 */
import mixin from './mixin'
import trackReportMixin from '../Common/CommonMixin/trackReport'
import linkTargetMixin from '../Common/CommonMixin/linkTarget'
import ModuleTextHeader from '../Common/ModuleTextHeader/touch.vue'
import DiffLinkLabel from '../Common/DiffLinkLabel/touch.vue'

export default {
  name: 'Ad5',
  components: {
    ModuleTextHeader,
    DiffLinkLabel,
  },
  mixins: [mixin, trackReportMixin, linkTargetMixin],
}
</script>
<style lang="scss" scoped>
.ad5-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .link-container {
    margin-right: 10px;
    margin-bottom: 10px;

    &:nth-child(2n + 2) {
      margin-right: 0;
    }
  }

  .ad5-wraper {
    width: 162px;
    height: 92px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background-color: rgba(0, 0, 0, 0.4);
    background-blend-mode: multiply;

    .title {
      font-size: 18px;
      font-weight: bold;
      color: #fff;
      line-height: 26px;
      text-align: center;
      padding: 0 20px;
    }
  }
}
</style>
