<template>
  <div class="page-rich-text">
    <div class="title">
      {{ title }}
    </div>
    <div class="content typo" v-html="content" />
  </div>
</template>

<script>
/**
 * 图文（二）web组件
 */
import mixin from './mixin'

export default {
  name: 'PageRichText',
  mixins: [mixin],
}
</script>

<style lang="scss">
@import "assets/css/typo.scss";
</style>

<style lang="scss" scoped>
.page-rich-text {
  background: #fff;
  padding: 20px;

  .title {
    margin-bottom: 20px;
    font-size: 23px;
  }

  .content {
    overflow: scroll;

    /deep/ table {
      width: auto !important;

      td {
        min-width: 80px;
      }
    }
  }
}
</style>
