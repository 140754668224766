<template>
  <div class="course-detail-column">
    <ModuleTextHeader :title="title" :describe="description" />
    <div class="course-detail-column-content">
      <div
        v-for="(item, index) in dataList"
        :key="index"
        v-sa-track="[{type: 'visually', event: 'component_card_show', data: cardshowTrackReport({ cardName: item.title })}]"
        class="card-wraper"
        @click="cardTrackReport({ cardName: item.title })"
      >
        <DiffLinkLabel :url="item.linkUrl">
          <div class="card-content">
            <div class="title text-overflow-ellipsis-2">
              {{ item.title }}
            </div>
            <div class="common-text text-overflow-ellipsis-3">
              {{ item.description }}
            </div>
          </div>
          <div class="card-bottom">
            <span class="price text-overflow-ellipsis">{{ item.price }}</span>
          </div>
        </DiffLinkLabel>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from './mixin'
import trackReportMixin from '../Common/CommonMixin/trackReport'
import ModuleTextHeader from '../Common/ModuleTextHeader/touch'
import DiffLinkLabel from '../Common/DiffLinkLabel/touch'

export default {
  components: { ModuleTextHeader, DiffLinkLabel },
  mixins: [mixin, trackReportMixin],
}
</script>

<style lang="scss" scoped>
.course-detail-column {
  padding: 0 16px;
}

.course-detail-column-content {
  .card-wraper {
    margin-bottom: 16px;
    background: #fff;
    border-radius: 10px;
    border: 1px solid #dee2e7;

    &:last-child {
      margin-bottom: 0;
    }

    .card-content {
      width: 342px;
      height: 162px;
      padding: 20px 12px;
      background: url('./assets/imgs/bg_touch.png') 0/100% no-repeat;
      color: var(--txt-color-lv1);

      .title {
        margin-bottom: 10px;
        font-size: 18px;
        font-weight: bold;
        line-height: 26px;
      }

      .common-text {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
      }
    }

    .card-bottom {
      padding: 14px 12px;
      text-align: right;
      border-radius: 0 0 10px 10px;

      .price {
        display: inline-block;
        width: 301px;
        font-size: 20px;
        font-weight: bold;
        color: var(--txt-color-link);
        line-height: 28px;
      }
    }
  }
}
</style>
