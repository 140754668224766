<template>
  <div class="header">
    <div class="head-title more-text1 text-overflow-ellipsis-2">
      {{ columnTitle }}
    </div>
    <DiffLinkLabel v-if="moreName" :url="moreUrl" :target="target">
      <div class="head-more" @click="moreClick">
        <div class="more-text2 text-overflow-ellipsis">
          {{ moreName }}
        </div>
        <div class="iconfont icon-more-right2" />
      </div>
    </DiffLinkLabel>
  </div>
</template>

<script>
/**
 *  模块头部
 */
import mixin from './mixin';
import DiffLinkLabel from '../DiffLinkLabel/touch.vue'

export default {
  components: {
    DiffLinkLabel,
  },
  mixins: [mixin],
}
</script>
<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  .more-text1 {
    max-width: 200px;
  }

  .more-text2 {
    max-width: 150px;
  }

  .head-title {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
  }

  .head-more {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: var(--txt-color-link);

    .icon-more-right2 {
      font-size: 12px;
      transform: scale(0.6);
      margin-top: 3px;
    }
  }
}
</style>
