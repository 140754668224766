<template>
  <div class="container">
    <div class="head">
      <div class="advator">
        <img :data-src="imageUrl" alt="" loading="lazy" class="swiper-lazy">
      </div>
      <div class="title">
        <div class="title1 text-overflow-ellipsis">
          {{ title }}
        </div>
      </div>
    </div>
    <div class="subtitle text-overflow-ellipsis-3">
      {{ subTitle }}
    </div>
    <div class="desc">
      {{ describe }}
    </div>
  </div>
</template>

<script>
import mixin from './mixin';

export default {
  mixins: [mixin],
}
</script>
<style lang="scss" scoped>
.container {
  padding: 31px 20px 20px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  width: 320px;
  height: 440px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 17px 23px 9px rgba(162, 170, 184, 0.06);
  border-radius: 10px;

  .head {
    display: flex;
    align-items: center;
    height: 50px;

    .advator {
      width: 40px;
      height: 40px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 1px solid rgba(0, 0, 0, 0.05);
      }
    }

    .title {
      margin-left: 10px;
      font-size: 16px;
      font-weight: 600;
      line-height: 19px;

      .title1 {
        max-width: 210px;
      }
    }
  }

  .subtitle {
    max-width: 280px;
    margin: 16px 0;
    font-size: 14px;
    line-height: 20px;
    color: #222;
  }

  .desc {
    max-height: 230px;
    word-break: break-word;
    overflow: hidden;
    font-size: 14px;
    font-weight: 500;
    color: #222;
    line-height: 18px;
  }
}
</style>
