<template>
  <div :class="['ad8-container', 'ad-container'+ styleType]">
    <ModuleTextHeader :title="title" :describe="describe" class="ad8-header" />
    <div class="content-container">
      <div class="content-wrapper">
        <div v-for="(item, index) in dataList" :key="index" class="content-slide">
          <DiffLinkLabel :url="item.linkUrl" :target="item.openType === 1 ? '_blank' : '_self'">
            <div class="ad8-content" @click="cardTrackReport({ cardName: item.title })">
              <img class="swiper-lazy" v-lazy-load :data-src="item.imageUrl" alt="">
              <div class="text-overflow-ellipsis-2 swiper-title">
                <div class="orange-triangle-wrap">
                  <span v-if="styleType === 9" class="orange-triangle" />
                </div>
                {{ item.title }}
              </div>
              <div class="swiper-desc">
                {{ item.describe }}
              </div>
            </div>
          </DiffLinkLabel>
        </div>
      </div>
    </div>
    <div v-if="buttonName" class="button btn-wraper" @click="buttonTrackReport({ buttonName })">
      <DiffLinkLabel :url="buttonUrl" :target="linkTarget">
        <div class="ad2-btn text-overflow-ellipsis">
          {{ buttonName }}
        </div>
      </DiffLinkLabel>
    </div>
  </div>
</template>
<script>
import mixin from './mixin'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import trackReportMixin from '../Common/CommonMixin/trackReport'
import linkTargetMixin from '../Common/CommonMixin/linkTarget'
import ModuleTextHeader from '../Common/ModuleTextHeader/touch.vue'
import DiffLinkLabel from '../Common/DiffLinkLabel/touch.vue'

export default {
  name: 'Ad8',
  /* eslint-disable-next-line */
  components: { ModuleTextHeader, DiffLinkLabel, Swiper, SwiperSlide, },
  directives: {
    swiper: directive,
  },
  mixins: [mixin, trackReportMixin, linkTargetMixin],
  data() {
    return {
      swiperOption: {
        slidesPerView: 'auto',
        loop: true,
        lazy: {
          loadPrevNext: true, // 预加载前一个后一个
        },
      },
    }
  },
}

</script>
<style lang="scss" scoped>
.ad8-container {
  overflow: hidden;

  .ad8-header {
    padding: 20px 40px 0 40px;
  }

  .content-container {
    margin: 0 20px;

    .content-slide {
      border: 1px solid #f1f1f1;
      border-radius: 16px;
      padding: 20px;
      margin-bottom: 20px;
    }

    .ad8-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-size: 14px;
      font-weight: bold;
      line-height: 20px;

      img {
        border-radius: 10px 10px 0 0;
      }

      .swiper-title {
        margin-top: 15px;
        font-size: 16px;
        font-weight: 600;
        color: var(--txt-color-lv1);
        line-height: 18px;
      }

      .swiper-desc {
        text-align: left;
        margin-top: 5px;
        font-size: 14px;
        font-weight: 400;
        color: var(--txt-color-lv1);
        word-break: break-word;
        line-height: 16px;
      }
    }
  }

  .btn-wraper {
    display: block;
    width: 285px;
    margin: 20px auto 0;
    padding: 10px 20px;
    font-size: 16px;
    line-height: 30px;
    font-weight: bold;
    background: var(--txt-color-link);
    border-radius: 32px;

    .ad2-btn {
      color: #fff;
    }
  }
}

.ad-container9 {
  .content-container {
    .content-slide {
      border: 0 none;

      img {
        height: 218px;
        border-radius: 16px;
      }

      .orange-triangle-wrap {
        display: inline-block;
        width: 10px;
        height: 12px;
        overflow: hidden;
        vertical-align: 2px;
        text-align: center;
        margin-right: 5px;

        .orange-triangle {
          display: inline-block;
          width: 7px;
          height: 7px;
          border-radius: 2px;
          background-color: var(--txt-color-link);
          transform: scaleY(1.2) rotate(45deg);
        }
      }
    }
  }
}
</style>
