<template>
  <div class="wrapper ad6-container">
    <ModuleTextHeader class="ad6-more" :title="title" :describe="describe" />
    <div v-if="dataList.length === 1" class="swiper-slide">
      <nuxt-link :to="'/teachers/detail/'+dataList[0].teacherId">
        <img v-lazy-load :data-src="`${dataList[0].avatar}?im=Resize=(480,480)`" alt="">
        <div class="swiper-name text-overflow-ellipsis-2">
          {{ dataList[0].sysName }}
        </div>
        <div v-if="dataList[0].education" class="swiper-tag text-overflow-ellipsis-2">
          {{ dataList[0].education }}
        </div>
      </nuxt-link>
    </div>
    <div v-else v-swiper:mySwiper="swiperOption" class="swiper-container">
      <div class="swiper-wrapper">
        <div
          v-for="(item, index) in dataList"
          :key="index"
          class="swiper-slide"
          @click="cardTrackReport({ cardName: item.sysName })"
        >
          <nuxt-link :to="'/teachers/detail/'+item.teacherId">
            <img :data-src="`${item.avatar}?im=Resize=(480,480)`" alt="" class="swiper-lazy">
            <div class="swiper-name text-overflow-ellipsis-2">
              {{ item.sysName }}
            </div>
            <div v-if="item.education" class="swiper-tag text-overflow-ellipsis-2">
              {{ item.education }}
            </div>
          </nuxt-link>
        </div>
      </div>
    </div>
    <div v-if="buttonName" class="button btn-wraper">
      <DiffLinkLabel :url="buttonUrl" :target="linkTarget">
        <div class="ad6-btn text-overflow-ellipsis" @click="buttonTrackReport({ buttonName })">
          {{ buttonName }}
        </div>
      </DiffLinkLabel>
    </div>
  </div>
</template>
<script>
import mixin from './mixin'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import trackReportMixin from '../Common/CommonMixin/trackReport'
import linkTargetMixin from '../Common/CommonMixin/linkTarget'
import ModuleTextHeader from '../Common/ModuleTextHeader/touch.vue'
import DiffLinkLabel from '../Common/DiffLinkLabel/touch.vue'

export default {
  name: 'Ad6',
  /* eslint-disable-next-line */
  components: { ModuleTextHeader, DiffLinkLabel, Swiper, SwiperSlide,},
  directives: {
    swiper: directive,
  },
  mixins: [mixin, trackReportMixin, linkTargetMixin],
  data() {
    return {
      swiperOption: {
        slidesPerView: 'auto',
        loop: true,
        lazy:{
          loadPrevNext:true, // 预加载前一个后一个
        },
        watchSlidesProgress: true,
        watchSlidesVisibility: true,
        preloadImages: false,
        loopAdditionalSlides: 2,
        loopedSlides: 5,
        autoplay: {
          delay: 2000,
          disableOnInteraction: false,
        },
        speed: 4000,
        autoplayDisableOnInteraction: true,
        observer: true,
        observeParents: true,
        observeSlideChildren: true,
      },
    }
  },
}

</script>
<style lang="scss" scoped>
.ad6-container {
  overflow: hidden;

  .swiper-container {
    margin-left: 20px;
  }

  .swiper-slide {
    width: 240px;
    margin-right: 16px;

    img {
      width: 240px;
      height: 240px;
    }

    .swiper-name {
      margin: 10px 0;
      max-width: 212px;
    }

    .swiper-tag {
      max-width: 212px;
      font-size: 14px;
      font-weight: 400;
      color: var(--txt-color-lv2);
      line-height: 18px;
    }
  }

  .btn-wraper {
    display: block;
    width: 285px;
    margin: 20px auto 0;
    padding: 10px 20px;
    font-size: 16px;
    line-height: 30px;
    font-weight: bold;
    background: var(--txt-color-link);
    border-radius: 32px;

    .ad6-btn {
      color: #fff;
    }
  }
}
</style>
