<template>
  <div class="score-wrapper">
    <div id="header-canvas" class="header">
      <p v-if="theme" class="title">
        {{ theme }}
      </p>
      <p v-if="themeIntro" class="intro">
        {{ themeIntro }}
      </p>
    </div>
    <div class="content">
      <div class="input-container">
        <van-form
          ref="SubmitInfo"
          @submit="handleSearch"
        >
          <div v-for="(item, index) in queryDataList" :key="index" class="input-wrapper">
            <van-field
              ref="inputField"
              v-model="formData.queryData[index]"
              :rules="item.rules"
              clearable
              left-icon="search"
              name="picker"
              :placeholder="item.title"
              class="item-field"
              @blur="onBlur(index)"
              @focus="onFocus(index)"
            />
          </div>
        </van-form>
        <van-field ref="checkboxWrapper" class="checkbox-wrapper">
          <template #input>
            <van-checkbox v-model="formData.subStatus" class="subStatus-checkbox normal-grace-checkbox">
              <div class="checkbox-content font-medium">
                <div class="legel-body" v-html="$t('page.scoreQuery.legalCheck')" />
              </div>
            </van-checkbox>
          </template>
        </van-field>
      </div>
      <div class="button-wrapper">
        <template v-if="formData.queryData[0] || formData.queryData[1] || formData.queryData[2]">
          <van-button round type="primary" class="button btn-submit" :text="queryWhereConfig.btnTitle" :loading="loading" @click="onSubmit" />
        </template>
        <template v-else>
          <div class="forbid-btn">
            {{ queryWhereConfig.btnTitle }}
          </div>
        </template>
      </div>
      <template v-if="showContent">
        <div class="search-result-content">
          <p class="text">
            {{ $t('page.scoreQuery.queryResult') }}
          </p>
          <div class="info-body">
            <div v-for="(item, index) in queryResultConfig.list" :key="index" class="student-info">
              <div class="label">
                {{ item.title }}
              </div>
              <div class="desc">
                <span v-if="item.type === 1">{{ resultData.studentName }}</span>
                <span v-if="item.type === 2">{{ resultData.score }}</span>
                <span v-if="item.type === 3">{{ resultData.scoreRank }}</span>
                <span v-if="item.type === 4">{{ resultData.awardType }}</span>
              </div>
            </div>
          </div>
          <template v-if="shareConfig.choose === 1">
            <div class="line-with-text">
              <span class="line-text">{{ $t('page.scoreQuery.share') }}</span>
            </div>
            <div class="share-option">
              <div
                v-if="shareConfig.channel.includes(first)"
                class="share-btn download"
                @click="handleDownload('download')"
              />
              <div
                v-if="shareConfig.channel.includes(second)"
                class="share-btn facebook"
                @click="shareToFacebook"
              />
              <div
                v-if="shareConfig.channel.includes(third)"
                class="share-btn ins"
                @click="handleDownload('ins')"
              />
              <div
                v-if="shareConfig.channel.includes(fourth)"
                class="share-btn whatsapp"
                @click="handleDownload('whatsapp')"
              />
            </div>
            <!-- 海报 -->
            <div id="poster-canvas" ref="poster" class="poster">
              <template v-if="shareConfig.show.includes(first)">
                <div class="name">
                  {{ resultData.studentName }}
                </div>
              </template>
              <template v-if="shareConfig.show.includes(third)">
                <div class="cup">
                  <svg xmlns="http://www.w3.org/2000/svg" width="900" overflow="visible">
                    <text
                      x="0"
                      y="0"
                      alignment-baseline="text-before-edge"
                      text-anchor="start"
                    >
                      {{ extractText(resultData.awardType) }}
                    </text>
                  </svg>
                </div>
              </template>
              <template v-if="shareConfig.show.includes(second)">
                <div class="rank">
                  {{ resultData.scoreRank }}
                </div>
              </template>
              <img :data-not-lazy="true" v-lazy-load :data-src="shareConfig.tempUrl" alt="">
            </div>
          </template>
        </div>
        <template v-if="certConfig.choose === 1">
          <div
            ref="certBody"
            class="cert-body"
          >
            <template v-if="certInfo.show.includes(first)">
              <div class="name">
                {{ resultData.studentName }}
              </div>
            </template>
            <template v-if="certInfo.show.includes(second)">
              <div class="school">
                {{ resultData.school }}
              </div>
            </template>
            <div v-if="isShowBtn" ref="operation" class="operation" data-html2canvas-ignore="true">
              <van-button class="button down-btn" :loading="certLoading" @click="certDownload">
                <div class="btn-group">
                  <div class="icon" />
                  <div class="text">
                    {{ certConfig.btnTitle }}
                  </div>
                </div>
              </van-button>
            </div>
            <img :data-not-lazy="true" v-lazy-load :data-src="certInfo.tempUrl" alt="" @load="isShowBtn = true">
          </div>
        </template>
      </template>
    </div>

    <van-popup v-model="showPopup" position="bottom">
      <div class="popup-body">
        <div class="save-img" @click="savePoster">
          {{ $t('page.scoreQuery.saveImage') }}
        </div>
        <div class="close-popup" @click="cancel">
          {{ $t('page.scoreQuery.cancel') }}
        </div>
        <template>
          <div class="model-image">
            <img v-if="touchPosterUrl" :data-not-lazy="true" v-lazy-load :data-src="touchPosterUrl" alt="">
            <van-loading v-else type="spinner" color="#fff" />
          </div>
        </template>
      </div>
    </van-popup>
  </div>
</template>
<script>

import mixin from './mixin';
import html2canvas from 'html2canvas';
import { oneApi } from 'config/api'

export default {
  mixins: [mixin],
  data() {
    return {
      lottieBox: null,
      play: false,
      showPopup: false,
      touchPosterUrl: '',
      base64PosterUrl: '',
      isShowBtn: false,
    };
  },
  methods: {
    onBlur(index) {
      const inputField = this.$refs.inputField[index].$el;
      if (inputField) {
        inputField.classList.remove('is-active');
      }
    },
    onFocus(index) {
      const inputField = this.$refs.inputField[index].$el;
      if (inputField) {
        inputField.classList.add('is-active');
      }
    },
    // 提交按钮事件
    onSubmit() {
      this.$refs.SubmitInfo.submit();
    },
    async handleSearch() {
      if (!this.formData.subStatus) {
        // this.$Toast('请先阅读并同意相关条款');
        const animationBody = this.$refs.checkboxWrapper.$el;
        animationBody.classList.add('shake');
        setTimeout(() => {
          animationBody.classList.remove('shake');
        }, 500);
        return;
      }
      this.submitTrack();

      this.loading = true;
      this.touchPosterUrl = '';
      const params = {
        filter: {
          matchingCondition1: this.formData.queryData[0],
          matchingCondition2: this.formData.queryData[1],
          matchingCondition3: this.formData.queryData[2],
        },
      };
      const res = await this.$axios.post(oneApi.getScoreQuery, params, { rewritePostBody: true })
      this.showContent = false
      if (res.code === 11004) {
        this.$Toast(res.msg);
        this.loading = false;
        return
      }
      if (res.data) {
        this.resultData = res.data;
        this.certInfo = this.certConfig.list.find((item) => item.award === res.data.awardType) || {};
        this.isShowBtn = false;
        if (Object.keys(this.certInfo).length === 0) {
          this.$Toast(this.$t('page.scoreQuery.notFoundTip'));
          this.loading = false;
          return
        }
        this.showContent = true;
        setTimeout(() => {
          this.getPoster();
        }, 500);
      } else {
        this.$Toast(this.$t('page.scoreQuery.notFoundTip'));
      }
      this.loading = false;
    },
    async handleDownload(type) {
      this.showPopup = true
      if (type === 'download') {
        this.shareImgTrack();
      } else if (type === 'ins') {
        this.shareInsTrack();
      } else if (type === 'whatsapp') {
        this.shareWhatsAppTrack();
      }
    },
    async shareToFacebook() {
      try {
        if (!this.checkSharePoster()) {
          return
        }
        const shareWindow = window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(this.touchPosterUrl)}`, '_blank');
        console.log(shareWindow, 'shareWindow');

        this.shareFaceBookTrack()
        // const timer = setInterval(() => {
        //   if (shareWindow.closed) {
        //     clearInterval(timer);
        //     this.$message.success('分享成功');
        //   }
        // }, 1000);
      } catch (error) {
        console.error('分享失败:', error);
      }
    },
    async getPoster() {
      if (!this.checkSharePoster()) {
        return
      }
      const dom = document.getElementById('poster-canvas');
      const rect = dom.getBoundingClientRect();
      const { width } = rect;
      const { height } = rect;
      console.log('dom', dom, width, height);
      html2canvas(dom, {
        scale: 1,
        useCORS: true,
        width,
        height,
        logging: true,
        allowTaint: true,
        async: true,
        ignoreElements: (element) => {
          if (element.getAttribute('loading') === 'lazy') {
            return true;
          }
          return false;
        },
      }).then(async (canvas) => {
        console.log('canvas', canvas);
        // 将 canvas 转换为图片 URL
        const base64Img = canvas.toDataURL('image/png');
        this.base64PosterUrl = base64Img;
        const filePaths = base64Img.split(';')[0];
        const base64WithoutHeader = base64Img.split(',')[1];
        const binaryString = atob(base64WithoutHeader);
        const binaryData = new Uint8Array(binaryString.length);
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < binaryString.length; i++) {
          binaryData[i] = binaryString.charCodeAt(i);
        }
        const { buffer } = binaryData;

        const url = await this.uploadToS3(buffer, filePaths)
        this.touchPosterUrl = url;
      }).catch((error) => {
        console.error('error', error);
      });
    },
    savePoster() {
      const link = document.createElement('a');
      link.href = this.base64PosterUrl;
      link.download = 'poster.png';
      link.click();
      this.cancel()
      this.$Toast('保存成功');
    },
    cancel() {
      this.showPopup = false
    },
  },
}
</script>
<style lang="scss" scoped>

::v-deep .van-popup.van-popup--bottom {
  height: 151px;
  border-radius: 16px 16px 0 0;
  padding: 20px 16px;
  overflow: visible;
}

.score-wrapper {
  padding: 0 16px;

  .header {
    margin-bottom: 16px;

    .title {
      font-size: 22px;
      color: var(--txt-color-lv1);
      line-height: 28px;
      font-weight: bold;
      text-align: center;
      word-wrap: break-word;
    }

    .intro {
      margin-top: 16px;
      font-size: 14px;
      color: var(--txt-color-lv2);
      line-height: 20px;
      text-align: center;
      word-wrap: break-word;
    }
  }

  .item-field {
    padding: 10px 16px;
    margin-bottom: 12px;
    border: 1px solid #e7e9ed;
    border-radius: 8px;
    overflow: visible;

    /deep/.van-field__control {
      padding-right: 20px;
      font-weight: 500;
      height: 30px;
      line-height: 30px;
      color: var(--txt-color-lv1);

      &::placeholder {
        font-size: 16px;
        font-weight: 500;
        color: #676769;
        line-height: 30px;
      }
    }

    /deep/ .van-field__left-icon .van-icon {
      font-size: 18px;
      line-height: 30px;
    }

    &::after {
      border-bottom: none;
    }

    &.van-field--error {
      margin-bottom: 35px;

      /deep/.van-field__control {
        color: var(--txt-color-lv2) !important;
      }
    }

    /deep/.van-field__control::placeholder {
      font-size: 14px;
      font-family: 'PingFangSC-Regular', 'PingFang SC', sans-serif;
      font-weight: 400;
      color: rgba(34, 34, 34, 0.4);
      line-height: 24px;
    }

    /deep/ .van-field__error-message {
      position: absolute;
      top: 45px;
      line-height: 16px;
    }

    /deep/ &.field-phone .van-field__error-message {
      left: -82px;
    }
  }
  .is-active {
    border: 1px solid var(--txt-color-link);
  }

  .content {
    width: 343px;

    .button-wrapper {
      margin-top: 20px;
      text-align: center;

      .btn-submit {
        font-weight: bold;
        line-height: 44px;
        width: 293px;
        height: 44px;
        background: var(--txt-color-link);
        border-radius: 22px;

        /deep/ .van-button__text {
          font-size: 16px;
        }
      }
      .forbid-btn {
        display: inline-block;
        width: 293px;
        height: 44px;
        background: var(--txt-color-link);
        opacity: 0.6;
        border-radius: 22px;
        font-size: 16px;
        font-family: 'PingFangSC-Medium', 'PingFang SC', sans-serif;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 44px;
      }
    }

    @keyframes shake {
      0%,
      100% {
        transform: translateX(0);
      }

      20% {
        transform: translateX(-5px);
      }

      40% {
        transform: translateX(5px);
      }

      60% {
        transform: translateX(-3px);
      }

      80% {
        transform: translateX(3px);
      }
    }

    .checkbox-wrapper {
      padding: 6px 0 0 0;

      &.shake {
        animation: shake 0.6s cubic-bezier(0.36, 0.07, 0.19, 0.97);
      }
    }

    .subStatus-checkbox {
      .checkbox-content {
        font-size: 14px;
        line-height: 20px;
        color: var(--txt-color-lv2);

        .legel-body {
          ::v-deep .link-text {
            color: var(--txt-color-link);
            text-decoration: underline;
          }
        }
      }
    }

    .search-result-content {
      width: 343px;
      height: auto;
      background: #fff;
      box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.04);
      border-radius: 8px;
      margin: 32px auto 10px;
      padding: 16px;

      .text {
        font-size: 16px;
        font-family: 'PingFangSC-Semibold', 'PingFang SC', sans-serif;
        font-weight: 600;
        color: #222;
        line-height: 26px;
        margin-bottom: 10px;
      }

      .info-body {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .student-info {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-family: 'PingFangSC-Medium', 'PingFang SC', sans-serif;
          color: #222;
          line-height: 16px;
          width: 100%;
          margin-bottom: 7px;

          .label {
            white-space: nowrap;
            margin-right: 12px;
            opacity: 0.6;
          }

          .desc {
            width: 100%;
            line-height: 16px;
            // height: 44px;
            background: #fff;
            border-radius: 8px;
            border: 1px solid rgba(23, 43, 77, 0.1);
            padding: 14px 12px;
            text-align: left;
          }
        }
      }

      .line-with-text {
        display: flex;
        align-items: center;
        text-align: center;
        font-size: 16px;
        font-family: 'PingFangSC-Medium', 'PingFang SC', sans-serif;
        color: rgba(34, 34, 34, 0.6);
        line-height: 18px;
        width: 100%;
        margin: 30px 0 20px;

        &::before,
        &::after {
          content: '';
          flex: 1;
          border-bottom: 1px solid rgba(23, 43, 77, 0.1);
        }

        &::before {
          margin-right: 10px;
        }

        &::after {
          margin-left: 10px;
        }
      }

      .line-text {
        white-space: nowrap;
      }

      .share-option {
        display: flex;
        justify-content: space-around;

        .share-btn {
          width: 40px;
          height: 40px;
        }

        .download {
          background: url("~assets/images/share/icon_download_touch.png") no-repeat;
          background-size: 100%;
        }

        .facebook {
          background: url("~assets/images/share/icon_facebook_touch.png") no-repeat;
          background-size: 100%;
        }

        .ins {
          background: url("~assets/images/share/icon_ins_touch.png") no-repeat;
          background-size: 100%;
        }

        .whatsapp {
          background: url("~assets/images/share/icon_whatsapp_touch.png") no-repeat;
          background-size: 100%;
        }
      }

      .poster {
        font-family: 'PingFang SC', sans-serif;
        position: absolute;
        top: -9999px;
        left: -9999px;
        width: 1000px;
        height: 1000px;
        display: flex;
        justify-content: center;
        align-items: center;

        .name,
        .cup,
        .rank {
          position: absolute;
        }

        .name {
          top: 159px;
          font-size: 44px;
          left: 67px;
          font-weight: bold;
          color: #fff;
        }

        .cup {
          width: 100%;
          font-weight: bold;
          stroke: #00a488;
          stroke-width: 20px;
          paint-order: stroke;
          stroke-linejoin: round;
          font-size: 115px;
          left: 60px;
          top: 360px;
          fill: #fffbac;
        }

        .rank {
          bottom: 215px;
          left: 68px;
          font-size: 90px;
          font-weight: bold;
          color: #fff;
        }

        img {
          width: 100%;
          height: auto;
        }
      }
    }

    .cert-body {
      position: relative;
      width: 100%;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;

      .name,
      .school {
        position: absolute;
      }

      .name {
        text-align: center;
        top: 225px;
        font-size: 16px;
        font-family: 'Playball', sans-serif;
        width: 310px;
        word-break: break-word;
      }

      .school {
        font-size: 12px;
        top: 265px;
        font-family: 'Baskvill', sans-serif;
      }

      .operation {
        position: absolute;
        bottom: 40px;

        .down-btn {
          width: 158px;
          height: 38px;
          box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.1);
          border-radius: 6px;
          font-size: 18px;
          font-family: 'PingFangSC-Semibold', 'PingFang SC', sans-serif;

          .btn-group {
            display: flex;
            align-items: center;

            .icon {
              width: 10px;
              height: 38px;
              background: url("~assets/images/share/icon-down.png") no-repeat;
              background-size: 100% 100%;
              margin-right: 4px;
            }

            .text {
              line-height: 16px;
            }
          }
        }
      }

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .popup-body {
    text-align: center;
    position: relative;

    .save-img {
      border-bottom: 1px solid #e7e9ed;
      padding-bottom: 20px;
      font-size: 16px;
      font-family: 'PingFangSC-Semibold', 'PingFang SC', sans-serif;
      font-weight: 600;
      color: #222;
      line-height: 18px;
    }

    .close-popup {
      margin-top: 20px;
      font-size: 16px;
      font-family: 'PingFangSC-Semibold', 'PingFang SC', sans-serif;
      font-weight: 600;
      color: rgba(34, 34, 34, 0.5);
      line-height: 18px;
    }

    .model-image {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 315px;
      height: 315px;
      top: -435px;
      left: calc(100% / 2 - 315px / 2);

      img {
        width: 315px;
        height: 315px;
        border-radius: 16px;
      }
    }
  }
}
</style>
