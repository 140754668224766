<template>
  <div class="courses-card-wrapper">
    <nuxt-link :to="linkUrl">
      <div class="card-content">
        <div class="title text-overflow-ellipsis-2">
          {{ courseDetail.showName }}
        </div>
        <div class="common-text text-overflow-ellipsis-3">
          {{ courseDetail.description }}
        </div>
        <div class="level">
          {{ courseDetail.levelDegreeName }}
        </div>
      </div>
      <CoursesColumnFooter :course-detail="courseDetail" :show-suitable="true" />
    </nuxt-link>
  </div>
</template>

<script>
import mixin from './mixin'
import CoursesColumnFooter from '../../Common/CoursesColumnFooter/touch'

export default {
  components: {
    CoursesColumnFooter,
  },
  mixins: [mixin],
}
</script>

<style lang="scss" scoped>
.card-content {
  position: relative;
  width: 342px;
  height: 164px;
  padding: 20px 12px;
  background: url('../assets/imgs/bg_touch.png') 0/100% no-repeat;
  border-radius: 10px 10px 0 0;

  .title {
    margin-bottom: 14px;
    font-size: 18px;
    font-weight: bold;
    color: var(--txt-color-lv1);
    line-height: 26px;
  }

  .level {
    position: absolute;
    top: 0;
    right: 12px;
    padding: 0 8px;
    font-size: 12px;
    font-weight: bold;
    line-height: 20px;
    color: #fff;
    background: var(--txt-color-link);
    border-radius: 0 0 8px 8px;
  }
}
</style>
