<template>
  <div class="wrapper">
    <ModuleTextHeader :title="title" :describe="description" />
    <div class="common-content">
      <CommonGoodCard
        v-for="(dataItem, index) in dataList"
        :key="index"
        v-sa-track="[{type: 'visually', event: 'universal_goods_card_show', data: getVisuallyTrackData(dataItem)}]"
        class="item"
        :card-data="dataItem"
        :has-border="true"
        @click.native="cardClickHandler(dataItem)"
      />
    </div>
  </div>
</template>

<script>
/**
 * 通用商品栏目
 */
import ModuleTextHeader from '../Common/ModuleTextHeader/touch.vue'
import CommonGoodCard from '@/components/global/FeatureComponents/Goods/CommonGoodCard/touch'

import mixin from './mixin'

export default {
  name: 'CommonGoodsColumn',
  components: {
    ModuleTextHeader,
    CommonGoodCard,
  },
  mixins: [mixin],
}
</script>
<style lang="scss">
.common-content {
  .item {
    margin-bottom: 20px;
  }
}
</style>
