<template>
  <van-overlay :show="isShowOverlay" z-index="11" @click="closeOverlay">
    <div class="wrapper">
      <div class="container" @click.stop>
        <img v-lazy-load :data-src="imageUrl" alt="">
        <div class="desc">
          {{ describe }}
        </div>
        <div class="desc-over" />
      </div>
      <img class="close-img" v-lazy-load data-src="./assets/images/icon_close_ad4.png" alt="" @click="closeOverlay">
    </div>
  </van-overlay>
</template>

<script>

export default {
  data() {
    return {
      isShowOverlay: false,
      imageUrl: '',
      describe: '',
    }
  },
  methods: {
    showOverlay({ imageUrl, describe }) {
      this.isShowOverlay = true
      this.imageUrl = imageUrl
      this.describe = describe
    },
    closeOverlay() {
      this.isShowOverlay = false
      this.imageUrl = ''
      this.describe = ''
    },
  },
}
</script>
<style lang="scss" scoped>
.wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 20px;

  .container {
    background-color: #fff;
    padding: 16px 16px 20px;
    font-size: 15px;
    font-weight: 600;
    position: relative;

    img {
      width: 303px;
      height: 175px;
      margin-bottom: 11px;
      color: #414141;
      line-height: 19px;
    }

    .desc {
      height: 202px;
      overflow: hidden;
    }

    .desc-over {
      width: 100%;
      height: 32px;
      padding: 0 16px;
      position: absolute;
      left: 0;
      bottom: 19px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
      border-radius: 1px;
    }
  }

  .close-img {
    margin-top: 20px;
    width: 38px;
    height: 38px;
  }
}
</style>
