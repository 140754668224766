<template>
  <van-popup
    v-model="showPopup"
    :overlay="overlay"
    overlay-class="popup-overlay"
    class="popup-video"
    @click-overlay="closePopup"
  >
    <div v-if="videoOptions.sources[0].src" class="video-wrapper">
      <video-player ref="videoPlayer" :options="videoOptions" class="vjs-theme-fantasy" />
    </div>
  </van-popup>
</template>

<script>
import VideoPlayer from '../VideoPlayer/index';

export default {
  components: {
    VideoPlayer,
  },
  data() {
    return {
      showPopup: false,
      overlay: true,
      videoOptions: {
        autoplay: true,
        controls: true,
        playsinline: true,
        sources: [
          {
            src: '',
            type: 'video/mp4',
          },
        ],
      },
    }
  },
  methods: {
    handleOpen(url) {
      this.videoOptions.sources[0].src = url;
      this.showPopup = true;
      setTimeout(() => {
        this.$refs.videoPlayer.play();
      }, 100);
    },
    closePopup() {
      this.showPopup = false;
      this.videoOptions.sources[0].src = ''
    },
  },
}
</script>

<style lang="scss">
.popup-video {
  background: none;

  .video-js {
    width: px2vw(650);
    height: px2vw(500);
    border-radius: px2vw(10);
    overflow: hidden;
  }
}
</style>
