<template>
  <div class="chat-message">
    <div class="chat-message-content" :class="isChater ? 'received' : 'sent'">
      <img v-if="isChater" class="chat-message-avatar" v-lazy-load data-src="../../imgs/avator-touch.png" alt="">
      <div class="chat-message-text">
        <i v-if="message.status === 'failed'" class="iconfont icon-failed" @click="handleRetry(message.contentId)" />
        <div class="caht-text-content text-content" :class="[isChater ? 'reply' : 'ask', {'text-content-card': message.type === 2}]">
          <template v-if="message.status === 'waiting'">
            <div class="chat-waiting-box">
              <template v-for="(item) in 3">
                <span :key="item" class="text-wait" />
              </template>
            </div>
          </template>
          <template v-else>
            <template v-if="message.type === 0">
              <div class="text-html" v-html="message.content" />
            </template>
            <template v-else-if="message.type === 1">
              <div class="content-img">
                <img :data-not-lazy="true" v-lazy-load :data-src="message.content" alt="" @click="handleImgClick">
              </div>
              <van-image-preview v-model="show" get-container="body" :show-index="false" :images="[message.content]" />
            </template>
            <template v-else>
              <ClassCard
                card-class="chat-class-card"
                :courses-mode="'card'"
                :card-data="message.content"
                :show-price-is-include="message.content.showPriceIsInclude===1"
                :total-lessons="message.content.spec.lessonCount" :left-lessons="message.content.spec.leftLesson"
                :show-right-top-tag="!message.content.spec.courseClosed"
                :tag-type="getClassHotTagType(message.content.store, message.content.sellStore, message.content.remainSellTime)"
                :show-back-stamp="true"
                :sub-platform-type="message.content.spec.subPlatformType"
                :platform-type="message.content.spec.platformType"
                :show-per-lesson="message.content.perShowPrice !== undefined"
                :card-link-new-window="true"
                show-venue-phone
                show-address-detail
              />
            </template>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from './mixin.js';
import coursesCommon from 'mixins/courses/common.js'
import ClassCard from '@/components/touch/courses/ClassCard'

export default {
  components: {
    ClassCard,
  },
  mixins: [mixin, coursesCommon],
  data() {
    return {
      show: false,
      index: 0,
      images: [
        'https://img01.yzcdn.cn/vant/apple-1.jpg',
      ],
    }
  },
  methods: {
    handleImgClick() {
      this.show = true
    },
  },
}
</script>

<style lang="scss" scoped>
@keyframes aa {
  0%,
  70%,
  100% {
    transform: scale(0.5);
  }

  35% {
    transform: scale(1);
  }
}

.chat-waiting-box {
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;

  .text-wait {
    width: 6px;
    height: 6px;
    background: #fff;
    border-radius: 3px;
    margin: 0 3px;
    animation: aa 1s linear infinite;

    &:nth-child(2) {
      animation-delay: 0.15s;
    }

    &:nth-child(3) {
      animation-delay: 0.3s;
    }

    &:last-child() {
      margin-right: 0;
    }
  }
}

.show-load {
  display: block;
}

.chat-message {
  margin-bottom: 20px;
  display: flex;
  position: relative;
}

.chat-message-content {
  width: 100%;
  display: flex;
  flex: 1;
}

.chat-message-avatar {
  width: 32px;
  height: 32px;
  object-fit: contain;
  margin-right: 8px;
  flex-shrink: 0;
}

.chat-message-text {
  display: flex;
  align-items: center;

  .text-content {
    padding: 20px;
    border-radius: 8px;
    display: flex;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;

    .content-img {
      img {
        width: 200px;
        height: auto;
        object-fit: contain;
        position: relative;
      }
    }

    .text-html {
      word-break: break-word;
      position: relative;
    }
  }

  .reply {
    background: #f5f5f5;
    color: var(--txt-color-lv1);

    .text-wait {
      background: #aca5a5;
    }
  }

  .ask {
    background: var(--txt-color-link);
    color: #fff;

    .text-wait {
      background: #fff;
    }
  }

  .text-content-card {
    padding: 0;
    border-radius: 9px;
    border: 1px solid #dee2e7;
  }

  .icon-failed {
    margin-right: 8px;
    color: #ff503f;
    font-size: 20px;
    cursor: pointer;
  }
}

.chat-message-content.received {
  .chat-message-text {
    flex: 1;
  }
}

.chat-message-content.sent {
  justify-content: flex-end;
}
</style>
