<template>
  <div class="UserCommentsBigPicture-box">
    <div v-if="columntheme" class="theme">
      {{ columntheme }}
    </div>
    <div v-if="describe" class="themeIntro">
      {{ describe }}
    </div>
    <div class="swiper-box">
      <div ref="swiper" v-swiper:mySwiper="swiperOption" class="swiper-container">
        <div class="swiper-wrapper">
          <div v-for="(item, index) in dataList" :key="index" class="swiper-slide">
            <div class="right">
              <img class="right_img" v-lazy-load :data-src="item.imageUrl" alt="" v-lazy-load>
            </div>
            <div class="left">
              <span class="left_title">{{ item.title }}</span>
              <span class="left_subheading">{{ item.subtitle }}</span>
              <span class="left_text">{{ item.describe }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import mixin from './mixin';
  import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';

  export default {
    components: { Swiper, SwiperSlide },
    directives: {
      swiper: directive,
    },
    mixins: [mixin],
    data(props) {
      return {
        swiperOption: {
          slidesPerView: 'auto',
          loop: false,
          preloadImages: false,
          loopAdditionalSlides: 1,
          loopedSlides: 3,
          autoplay: {
            delay: 3000,
            disableOnInteraction: false,
          },
          lazy:{
            loadPrevNext:true, // 预加载前一个后一个
          },
          speed: 500,
          autoplayDisableOnInteraction: true,
          observer: true,
          observeParents: true,
          observeSlideChildren: true,
        },
      };
    },
    mounted() { },
    methods: {},
  };
</script>

<style lang="scss" scoped>
  .UserCommentsBigPicture-box {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    margin: 0 auto;

    .theme {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 24px;
      font-family: Montserrat-Bold, Montserrat;
      font-weight: bold;
      color: #222222;
      line-height: 24px;
      text-align: center;
      margin: 0 auto 20px;
    }

    .themeIntro {
      font-size: 14px;
      font-family: Montserrat-Medium, Montserrat;
      font-weight: 500;
      color: #222;
      line-height: 20px;
      text-align: center;
      margin-bottom: 20px;
      text-align: center;
    }

    /* .swiper-container {
      --swiper-navigation-color: #f60;
      --swiper-navigation-size: 30px;
    } */

    .swiper-box {}

    .swiper-slide {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .left {
        width: 100%;
        /* height: 236px; */
        padding: 16px;

        .left_title {
          display: block;
          font-weight: bold;
          color: #222222;
          font-size: 14px;
          line-height: 16px;
          font-family: Montserrat-Bold, Montserrat;
        }

        .left_subheading {
          display: inline-block;
          padding: 4px 8px 4px 8px;
          background: #FFF3E0;
          border-radius: 4px;
          font-size: 10px;
          line-height: 12px;
          margin-top: 12px;
          margin-left: 8px;
          font-family: Montserrat-Medium, Montserrat;
          font-weight: 500;
          color: #FFA827;
        }

        .left_text {
          display: inline-block;
          margin-top: 20px;
          font-size: 12px;
          line-height: 20px;
          display: -webkit-box;
          font-family: Montserrat-Medium, Montserrat;
          font-weight: 500;
          color: #4E4E4E;
        }
      }

      .right {
        width: 100%;
        height: 200px;

        .right_img {
          display: block;
          width: 100%;
          height: 100%;
          border-radius: 10px;
        }
      }
    }
  }
</style>